import "./SummaryPdf.css";
import React, { Fragment } from "react";

function SummaryPdf({ summaryChapters, startIndex = 0 }) {
  function generateDots(title) {
    const maxLength = 180;
    const numDots = maxLength - title.length;
    return ".".repeat(Math.max(0, numDots));
  }

  return (
    <>
      {summaryChapters.map((summaryChapter, index) => (
        <Fragment key={`chapter-${index}`}>
          <div possible-page-break='true' />
          <div className='summary-chapter-container'>
            <a href={"#" + generateHrefPdf(summaryChapter.title)} className='chapter-title-container'>
              <span className='chapter-title'>
                {startIndex + index + 1}. {summaryChapter.title}
              </span>
              <span className='summary-dots-line'>
                {generateDots(`${startIndex + index + 1}. ${summaryChapter.title}`)}
              </span>
              <span>{summaryChapter.page}</span>
            </a>
            {summaryChapter.subItems &&
              summaryChapter.subItems.map((item, subIndex) => (
                <a
                  href={"#" + generateHrefPdf(item.subtitle)}
                  key={`chapter-${index}-subitem-${subIndex}`}
                  className='chapter-subtitle-container'>
                  <span className='subtitle-bullet-point'>•</span>
                  <span>{item.subtitle}</span>
                  <span className='summary-dots-line'>{generateDots(item.subtitle)}</span>
                  <span>{item.page}</span>
                </a>
              ))}
          </div>
        </Fragment>
      ))}
    </>
  );
}

export default SummaryPdf;

export function getTitleWithoutNumber(title) {
  if (!title) return "";
  return title.replace(/^\d+/, "");
}

export function generateHrefPdf(title) {
  if (!title) return "";
  return title
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replaceAll(/[^a-zA-Z0-9 ]/g, "")
    .replaceAll(" ", "-")
    .toLowerCase();
}
