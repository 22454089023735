import React, { useContext, useState, useEffect } from "react";
import "./FolderFinancingPlan.css";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import Download from "../../../../../../../components/atoms/icons/general/download/Download";
import { useWindowSize } from "@uidotdev/usehooks";
import Tuto from "../../../../../../../components/atoms/icons/general/tuto/Tuto";
import Rapport from "../../../../../../../components/atoms/icons/header/rapport/Rapport";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../../../../../context/AppContext";
import ImportFinancingPlansFolderModal from "../../../../../../../components/forms/modals/account/importFinancingPlansFolderModal/ImportFinancingPlansFolderModal";
import {
  deleteFinancingPlanFromFolderApi,
  deleteAllFinancingPlansFromFolderApi,
} from "../../../../../../../api/FolderApi";
import UnfilledButton from "../../../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import Pen from "../../../../../../../components/atoms/icons/account/pen/Pen";
import Trash from "../../../../../../../components/atoms/icons/general/trash/Trash";
import Bracket from "../../../../../../../components/atoms/icons/general/bracket/Bracket";
import { useAuth } from "../../../../../../../context/AuthContext";
import { Link } from "react-router-dom";

function FolderFinancingPlan({ folder, triggerFolderUpdate }) {
  const { setModalContent, setModalVisible, createNotification, showTooltip } = useContext(AppContext);
  const [openedFinancingPlanIndex, setOpenedFinancingPlanIndex] = useState(-1);
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { getUuid } = useAuth();

  function toggleOpenedFinancingPlanIndex(index) {
    setOpenedFinancingPlanIndex(prev => (prev === index ? -1 : index));
  }

  function openImportFinancingPlanModal() {
    setModalContent({
      title: "Importer des plans de financement",
      content: (
        <ImportFinancingPlansFolderModal
          folderId={folder.data.folder_id}
          getUuid={getUuid}
          folder={folder}
          onFinancingPlanAdded={triggerFolderUpdate}
        />
      ),
    });
    setModalVisible(true);
  }

  function handleDeleteFinancingPlan(financingPlanId) {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir retirer ce plan de financement de votre dossier ?<br />
            Cette action est irréversible.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteFinancingPlan(financingPlanId);
              }}>
              Retirer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteFinancingPlan(financingPlanId) {
    try {
      await deleteFinancingPlanFromFolderApi(folder.data.folder_id, financingPlanId, true);

      triggerFolderUpdate();

      createNotification(<>Le plan de financement a été retiré avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait du plan de financement</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function handleDeleteAllFinancingPlans() {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir retirer{" "}
            {folder.financing_plans.length > 1
              ? "tous les plans de financement du dossier"
              : "le plan de financement du dossier"}{" "}
            ?
            <br /> Cette action est irréversible et retirera également les plans de financement des livrables associés.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteAllFinancingPlans();
              }}>
              Retirer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteAllFinancingPlans() {
    try {
      await deleteAllFinancingPlansFromFolderApi(folder.data.folder_id, true);

      triggerFolderUpdate();

      createNotification(<>Tous les plans de financement ont été retirés avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait des plans de financement</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <div>
      <div className='tab-content-header'>
        <h2>
          <Rapport />
          Plan de financement
          {/* 
          <span className='tuto-icon'>
            <Tuto onClick={() => showTooltip("")} />
          </span>
          */}
        </h2>
        <p>Retrouvez ici tous les plans de financement liés à votre dossier.</p>
      </div>
      <div className='folder-plan-buttons'>
        <FilledButton to='/plan-financement'>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer un plan de financement
        </FilledButton>
        <FilledButton onClick={openImportFinancingPlanModal}>
          <Download width={width > 576 ? "30px" : "20px"} /> Importer un plan de financement
        </FilledButton>
      </div>
      <div className='folder-table-plan'>
        <div className='folder-table-plan-header'>
          <p>Nom du plan de financement</p>
          <p>Type</p>
          <p>Actions</p>
        </div>
        <div className='folder-table-plan-body'>
          {folder?.financing_plans?.length ? (
            folder.financing_plans.map((plan, index) => (
              <div className='financing-plan-item' key={plan.financing_plan_id}>
                <p>{plan.financing_plan_name}</p>
                <p>
                  {plan.financing_plan_type === "fdc"
                    ? "Fonds de commerce"
                    : plan.financing_plan_type === "murs"
                      ? "Murs commerciaux"
                      : "Type inconnu"}
                </p>
                <div className='financing-plan-dropdown' onClick={() => toggleOpenedFinancingPlanIndex(index)}>
                  <Bracket
                    rotation={openedFinancingPlanIndex === index ? "0" : "180deg"}
                    width='15px'
                    color='var(--white)'
                    className='dropdown-icon-financing-plans'
                  />
                  <ul className={`dropdown-menu ${openedFinancingPlanIndex === index ? "show" : ""}`}>
                    <li>
                      <Link to={`/plan-financement/${plan.financing_plan_type}/${plan.financing_plan_id}`}>
                        <Pen width='22px' /> Modifier
                      </Link>
                    </li>
                    <li>
                      <button onClick={() => handleDeleteFinancingPlan(plan.financing_plan_id)}>
                        <Trash />
                        Retirer du dossier
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            ))
          ) : (
            <div className='info-block-plan'>
              <p>Aucun plan de financement dans ce dossier</p>
            </div>
          )}
        </div>
        <div className='folder-table-plan-footer mt-sm'>
          {folder.financing_plans.length > 0 && (
            <UnfilledButton bgColor='var(--dark-red)' onClick={handleDeleteAllFinancingPlans}>
              {folder.financing_plans.length > 1
                ? "Retirer tous les plans de financement du dossier"
                : "Retirer le plan de financement du dossier"}
            </UnfilledButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default FolderFinancingPlan;
