import React, { useState, useEffect, useContext } from "react";
import "./FolderCreditSimulation.css";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import Download from "../../../../../../../components/atoms/icons/general/download/Download";
import { useWindowSize } from "@uidotdev/usehooks";
import Tuto from "../../../../../../../components/atoms/icons/general/tuto/Tuto";
import Rapport from "../../../../../../../components/atoms/icons/header/rapport/Rapport";
import { useNavigate } from "react-router-dom";
import UnfilledButton from "../../../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import AppContext from "../../../../../../../context/AppContext";
import {
  deleteAllCreditSimulatorsFromFolderApi,
  deleteCreditSimulatorFromFolderApi,
} from "../../../../../../../api/FolderApi";
import Bracket from "../../../../../../../components/atoms/icons/general/bracket/Bracket";
import Pen from "../../../../../../../components/atoms/icons/account/pen/Pen";
import Trash from "../../../../../../../components/atoms/icons/general/trash/Trash";
import ImportCreditSimulatorsFolderModal from "../../../../../../../components/forms/modals/account/importCreditSimulatorsFolderModal/ImportCreditSimulatorsFolderModal";
import { useAuth } from "../../../../../../../context/AuthContext";
import { Link } from "react-router-dom";

function FolderCreditSimulation({ folder, triggerFolderUpdate }) {
  const { setModalContent, setModalVisible, createNotification, showTooltip } = useContext(AppContext);
  const [openedCreditSimulatorIndex, setOpenedCreditSimulatorIndex] = useState(-1);
  const navigate = useNavigate();
  const { getUuid } = useAuth();

  const { width } = useWindowSize();

  function toggleOpenedCreditSimulatorIndex(index) {
    setOpenedCreditSimulatorIndex(prev => (prev === index ? -1 : index));
  }

  function openImportCreditSimulatorModal() {
    setModalContent({
      title: "Importer une simulation de prêt",
      content: (
        <ImportCreditSimulatorsFolderModal
          folderId={folder.data.folder_id}
          getUuid={getUuid}
          folder={folder}
          onCreditSimulatorAdded={triggerFolderUpdate}
        />
      ),
    });
    setModalVisible(true);
  }

  function handleDeleteCreditSimulator(creditSimulatorId) {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir retirer ce simulation de prêt de votre dossier ?<br />
            Cette action est irréversible.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteCreditSimulator(creditSimulatorId);
              }}>
              Retirer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteCreditSimulator(creditSimulatorId) {
    try {
      await deleteCreditSimulatorFromFolderApi(folder.data.folder_id, creditSimulatorId, true);

      triggerFolderUpdate();

      createNotification(<>La simulation de prêt a été retiré avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait de la simulation de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function handleDeleteAllCreditSimulators() {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir retirer tous les simulations de prêt de votre dossier ?<br />
            Cette action est irréversible.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteAllCreditSimulators();
              }}>
              Retirer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteAllCreditSimulators() {
    try {
      await deleteAllCreditSimulatorsFromFolderApi(folder.data.folder_id, true);

      triggerFolderUpdate();

      createNotification(<>Tous les simulations de prêt ont été retirés avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait des simulations de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <div>
      <div className='tab-content-header'>
        <h2>
          <Rapport />
          Simulation de prêt
          {/*
          <span className='tuto-icon'>
            <Tuto onClick={() => showTooltip("")} />
          </span>
          */}
        </h2>
        <p>Retrouvez ici tous les simulations de prêt liés à votre dossier.</p>
      </div>
      <div className='folder-simulateur-buttons'>
        <FilledButton to='/simulateur-credit'>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer une simulation de prêt
        </FilledButton>
        <FilledButton onClick={openImportCreditSimulatorModal}>
          <Download width={width > 576 ? "30px" : "20px"} /> Importer une simulation de prêt
        </FilledButton>
      </div>
      <div className='folder-table-simulateur'>
        <div className='folder-table-simulateur-header'>
          <p>Nom de la simulation de prêt</p>
          <p>Actions</p>
        </div>
        <div className='folder-table-simulateur-body'>
          {folder.credit_simulators && folder.credit_simulators.length ? (
            folder.credit_simulators.map((creditSimulator, index) => {
              return (
                <div key={creditSimulator?.credit_id} className='credit-simulator-item'>
                  <p>{creditSimulator?.credit_name}</p>
                  <div className='simulateur-dropdown' onClick={() => toggleOpenedCreditSimulatorIndex(index)}>
                    <Bracket
                      rotation={openedCreditSimulatorIndex === index ? "0" : "180deg"}
                      width='15px'
                      color='var(--white)'
                      className='dropdown-icon-simulators'
                    />
                    <ul className={`dropdown-menu ${openedCreditSimulatorIndex === index ? "show" : ""}`}>
                      <li>
                        <Link to={`/simulateur-credit/${creditSimulator?.credit_id}`}>
                          <Pen width='22px' /> Modifier
                        </Link>
                      </li>
                      <li>
                        <button onClick={() => handleDeleteCreditSimulator(creditSimulator?.credit_id)}>
                          <Trash />
                          Retirer du dossier
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })
          ) : (
            <div className='info-block-simulateur'>
              <p>Aucune simulation de prêt n'a été ajouté à ce dossier.</p>
            </div>
          )}
        </div>
        <div className='folder-table-simulateur-footer mt-sm'>
          {folder.credit_simulators.length > 0 && (
            <UnfilledButton bgColor='var(--dark-red)' onClick={handleDeleteAllCreditSimulators}>
              {folder.credit_simulators.length > 1
                ? "Retirer tous les simulations de prêt"
                : "Retirer la simulation de prêt"}
            </UnfilledButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default FolderCreditSimulation;
