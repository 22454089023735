import React, { useContext, useState, useEffect } from "react";
import "./EditPdfCustomisationForm.css";
import { set, useForm } from "react-hook-form";
import AppContext from "../../../../context/AppContext";
import FileInput from "../../../molecules/formComponents/fileInput/FileInput";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../molecules/buttons/unfilledButton/UnfilledButton";
import defaultHeaderLogo from "../../../../assets/logos/logo_emc_icon_light.svg";
import defaultFooterLogo from "../../../../assets/logos/logo_emc_full_light.svg";
import defaultCoverLogo from "../../../../assets/logos/logo_emc_full_dark.svg";
import { updateUserPdfCustomisationApi } from "../../../../api/AccountApi";
import { getCompressedImage, imageToFile } from "../../../../utils/Utils";
import { HexColorInput, HexColorPicker } from "react-colorful";
import Trash from "../../../atoms/icons/general/trash/Trash";
import ReportExample from "../../../atoms/icons/account/reportExample/ReportExample";

function EditPdfCustomisationForm({ customisation, getUuid, onChange = () => {}, setModalVisible }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { createNotification } = useContext(AppContext);
  const defaultValues = {
    header_image: { name: "logo_emc_full_light.svg", data: defaultHeaderLogo },
    cover_image: { name: "logo_emc_full_dark.svg", data: defaultCoverLogo },
    footer_image: { name: "logo_emc_icon_light.svg", data: defaultFooterLogo },
    banner_background_color: "#252b41",
    banner_text_color: "#ffffff",
  };
  const [selectedCustomisation, setSelectedCustomisation] = useState(defaultValues);
  const [rgbValues, setRgbValues] = useState({ r: 0, g: 0, b: 0 });

  useEffect(() => {
    if (!customisation) return;

    const tmp = selectedCustomisation;
    const tmpArray = ["header_image", "footer_image", "cover_image"].filter(key => !customisation?.[key]);

    tmp.header_image = customisation.header_image
      ? { name: null, data: customisation.header_image }
      : defaultValues.header_image;
    tmp.footer_image = customisation.footer_image
      ? { name: null, data: customisation.footer_image }
      : defaultValues.footer_image;
    tmp.cover_image = customisation.cover_image
      ? { name: null, data: customisation.cover_image }
      : defaultValues.cover_image;
    tmp.banner_text_color = customisation.banner_text_color ?? defaultValues.banner_text_color;
    tmp.banner_background_color = customisation.banner_background_color ?? defaultValues.banner_background_color;

    setSelectedCustomisation(tmp);
    setRgbValues(hexToRgb(tmp.banner_background_color));

    tmpArray.forEach(type => handleFileClear(type));
  }, [customisation]);

  async function onSubmit() {
    try {
      const data = { ...selectedCustomisation };
      const imagesToCheck = ["header_image", "footer_image", "cover_image"];

      imagesToCheck.forEach(key => {
        if (!data[key]?.name && data[key] !== "none") delete data[key];
      });

      const res = (await updateUserPdfCustomisationApi(getUuid(), data)).data;

      onChange(res);

      createNotification(<>Vos paramètres ont été sauvegardés avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'envoi de vos paramètres. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  function handleFileChange(compressedFile, type) {
    if (compressedFile) setSelectedCustomisation(prev => ({ ...prev, [type]: compressedFile }));
  }

  function handleFileClear(type) {
    setSelectedCustomisation(prev => ({ ...prev, [type]: "none" }));
  }

  function getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    return yiq >= 128 ? "#000000" : "#FFFFFF";
  }

  function handleBackgroundColorChange(color) {
    const textColor = getContrastYIQ(color);

    setSelectedCustomisation(prev => ({ ...prev, banner_background_color: color, banner_text_color: textColor }));
  }

  function resetCustomisation() {
    const tmp = {
      banner_text_color: defaultValues.banner_text_color,
      banner_background_color: defaultValues.banner_background_color,
    };
    const imagesToCheck = ["header_image", "footer_image", "cover_image"];

    setRgbValues(hexToRgb(defaultValues.banner_background_color));

    for (const key of imagesToCheck) {
      imageToFile(defaultValues[key].data, defaultValues[key].name, "img/svg", async file => {
        const compressedFile = await getCompressedImage(file);
        tmp[key] = compressedFile;
        if (key === "cover_image") setSelectedCustomisation(tmp);
      });
    }
  }

  function rgbToHex(rgb) {
    return (
      "#" +
      Object.values(rgb)
        .map(string => parseFloat(string))
        .map((number, index) => (index === 3 ? Math.round(number * 255) : number))
        .map(number => number.toString(16))
        .map(string => (string.length === 1 ? "0" + string : string))
        .join("")
    );
  }

  function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  function onRgbaChange(e, key) {
    if (e.target.value > 255) e.target.value = 255;
    if (e.target.value < 0) e.target.value = 0;
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    if (e.target.value.length === 0) e.target.value = 0;

    const rgb = { ...rgbValues, [key]: e.target.value };
    const hex = rgbToHex(rgb);

    handleBackgroundColorChange(hex);
    setRgbValues(rgb);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='customisation-pdf-container'>
        <div
          className='customisation-preview-container'
          style={{
            "--background-color": selectedCustomisation.banner_background_color,
            "--text-color": selectedCustomisation.banner_text_color,
          }}>
          <div className='banner-color-logo'>
            {selectedCustomisation?.header_image?.data && (
              <img
                src={selectedCustomisation?.header_image?.data}
                alt='Aperçu du logo'
                className='header-logo-preview'
              />
            )}
          </div>
          {selectedCustomisation?.cover_image?.data ? (
            <img src={selectedCustomisation?.cover_image?.data} alt='Aperçu du logo' className='cover-logo-preview' />
          ) : (
            <div className='cover-logo-preview' />
          )}
          <p>Rapport d'estimation</p>
          <div className='banner-color-logo'>
            {selectedCustomisation?.footer_image?.data ? (
              <img
                src={selectedCustomisation?.footer_image?.data}
                alt='Aperçu du logo'
                className='footer-logo-preview'
              />
            ) : (
              <div />
            )}
            <p>Page X/XX</p>
          </div>
          <p className='customisation-pagination'>Page de garde</p>
        </div>
        <div
          className='customisation-preview-container page-2'
          style={{
            "--background-color": selectedCustomisation.banner_background_color,
            "--text-color": selectedCustomisation.banner_text_color,
          }}>
          <div className='banner-color-logo'>
            {selectedCustomisation?.header_image?.data && (
              <img
                src={selectedCustomisation?.header_image?.data}
                alt='Aperçu du logo'
                className='header-logo-preview'
              />
            )}
          </div>
          <ReportExample />
          <div className='banner-color-logo'>
            {selectedCustomisation?.footer_image?.data ? (
              <img
                src={selectedCustomisation?.footer_image?.data}
                alt='Aperçu du logo'
                className='footer-logo-preview'
              />
            ) : (
              <div />
            )}
            <p>Page X/XX</p>
          </div>
          <p className='customisation-pagination'>Contenu du rapport</p>
        </div>
        <div className='customisation-pdf-buttons'>
          <FileInput
            name='header_image'
            buttonText="Modifier mon logo d'entête"
            onChange={e => handleFileChange(e, "header_image")}
            accept='image/*'
            required
            removeButton={false}
            padding='10px 20px'
          />
          <div className='reset-customisation-button' onClick={() => handleFileClear("header_image")}>
            <Trash color='white' />
          </div>
          <FileInput
            name='cover_image'
            buttonText='Modifier mon logo de couverture'
            onChange={e => handleFileChange(e, "cover_image")}
            accept='image/*'
            required
            removeButton={false}
            padding='10px 20px'
          />
          <div className='reset-customisation-button' onClick={() => handleFileClear("cover_image")}>
            <Trash color='white' />
          </div>
          <FileInput
            name='footer_image'
            buttonText='Modifier mon logo de pied de page'
            onChange={e => handleFileChange(e, "footer_image")}
            accept='image/*'
            required
            removeButton={false}
            padding='10px 20px'
          />
          <div className='reset-customisation-button' onClick={() => handleFileClear("footer_image")}>
            <Trash color='white' />
          </div>
          <HexColorPicker
            color={selectedCustomisation.banner_background_color}
            onChange={handleBackgroundColorChange}
          />
          <div className='hex-color-container'>
            HEX&nbsp;:
            <HexColorInput
              prefixed
              color={selectedCustomisation.banner_background_color}
              onChange={handleBackgroundColorChange}
            />
          </div>
          <div className='rgba-inputs-container'>
            R&nbsp;:
            <input
              className='rgba-input'
              value={rgbValues.r}
              maxLength={3}
              max={255}
              min={0}
              type='number'
              onChange={e => onRgbaChange(e, "r")}
            />
            G&nbsp;:
            <input
              className='rgba-input'
              value={rgbValues.g}
              maxLength={3}
              max={255}
              min={0}
              type='number'
              onChange={e => onRgbaChange(e, "g")}
            />
            B&nbsp;:
            <input
              className='rgba-input'
              value={rgbValues.b}
              maxLength={3}
              max={255}
              min={0}
              type='number'
              onChange={e => onRgbaChange(e, "b")}
            />
          </div>
          <div className='reset-customisation-text' onClick={resetCustomisation}>
            Réinitialiser les paramètres
          </div>
        </div>
      </div>
      <div className='modal-buttons-row mt-md'>
        <UnfilledButton onClick={() => setModalVisible(false)}>Annuler</UnfilledButton>
        <FilledButton type='submit'>Enregistrer</FilledButton>
      </div>
    </form>
  );
}

export default EditPdfCustomisationForm;
