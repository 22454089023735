import { useWindowSize } from "@uidotdev/usehooks";
import Banner from "../../../components/molecules/banner/Banner";
import "./GuidesAgentsImmos.css";
import React, { useContext, useEffect, useState } from "react";
import ThumbnailsGroup from "../../../components/organisms/thumbnailsGroup/ThumbnailsGroup";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import FilledButton from "../../../components/molecules/buttons/filledButton/FilledButton";
import Thumbnail from "../../../components/molecules/thumbnail/Thumbnail";
import AppContext from "../../../context/AppContext";
import { getPrettyText } from "../../../utils/Utils";
import { fetchAllGuideAgentsImmosApi } from "../../../api/ToolsApi";
import { useNavigate } from "react-router-dom";

function GuidesAgentsImmos() {
  const [articles, setArticles] = useState([]);

  const { _, width } = useWindowSize();
  const { setAppLoaderVisible } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    setAppLoaderVisible(true);
    fetchArticles();
  }, []);

  async function fetchArticles() {
    try {
      const data = (await fetchAllGuideAgentsImmosApi()).data;
      data.map((article, index) => {
        data[index] = {
          title: getPrettyText(article.title),
          picture: `${process.env.REACT_APP_API_URL}/assets/GuidesAgentsImmos/${article.newsletter_url}/miniature/${article.image_path}`,
          linkText: "Lire le guide",
          article_url: article.newsletter_url,
        };
      });

      setArticles(data);

      setAppLoaderVisible(false);
    } catch (error) {
      navigate("/404");
    }
  }

  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            {
              name: "Accueil",
              to: "/",
            },
            {
              name: "Conseils: Nos guides pour agents immos",
              to: "/guides-agents-immos",
            },
          ]}
        />
        <Banner
          title={<>Nos guides pour agents immos</>}
          subtitle='Abonnez-vous en fin de page à notre Newsletter pour recevoir nos méthodes métiers tous les mois'
          className='mb-lg'
        />
        {width > 576 && articles.length ? (
          <ThumbnailsGroup data={articles} />
        ) : (
          <>
            <div className='mobile-thumbnails-col'>
              {articles.map((article, key) => {
                return <Thumbnail {...article} link={article.article_url} key={key} />;
              })}
            </div>
            <div className='centered'>
              <FilledButton to='/debut-parcours'>Je démarre une estimation</FilledButton>
            </div>
          </>
        )}
      </section>
    </>
  );
}

export default GuidesAgentsImmos;
