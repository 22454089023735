import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../molecules/formComponents/input/Input";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea.js";
import { deleteEstimationImageApi, updateEstimationImagesApi } from "../../../../../api/EstimationApi.js";
import FileInputPreview, {
  getFileInputValue,
} from "../../../../molecules/formComponents/fileInputPreview/FileInputPreview.js";
import AppContext from "../../../../../context/AppContext.js";
import { max2250Validation } from "../../../../../utils/formValidation/FormValidation.js";

function BienAEvaluer({ id, index }) {
  const {
    register,
    setValue,
    unregister,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { createNotification } = useContext(AppContext);
  const {
    step,
    setStep,
    moveToNextStep,
    submitFormsMc,
    postValues,
    getStepToGo,
    setGoToStep,
    handleTabs,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);

  const inputForm = { register, unregister, setValue };
  const [images, setImages] = useState({ image_1: null, image_2: null, image_3: null });

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["infos"], setValue, estimationValues);
    setImages({
      image_1: estimationValues?.images?.image_1,
      image_2: estimationValues?.images?.image_2,
      image_3: estimationValues?.images?.image_3,
    });
  }, [estimationValues]);

  async function formSubmit(values) {
    const images = values.images;
    delete values.images;

    const estimationId = await postValues(values);

    postImages(estimationId, images);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function postImages(estimationId, images) {
    try {
      const payload = {
        image_1: getFileInputValue(images.image_1),
        image_2: getFileInputValue(images.image_2),
        image_3: getFileInputValue(images.image_3),
      };

      const res = (await updateEstimationImagesApi(estimationId, payload)).data;

      setImages({
        image_1: res.image_1_path,
        image_2: res.image_2_path,
        image_3: res.image_3_path,
      });
    } catch (e) {
      createNotification(
        <>Une erreur est survenue lors de l'envoi de vos images. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function onImageClear(e, name) {
    try {
      if (!estimationValues.id) return;
      await deleteEstimationImageApi(estimationValues.id, name.split(".")[1]);
    } catch (e) {}
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <fieldset className='borderless-fieldset'>
        <h2>le bien à évaluer</h2>
        <Input
          label='intitulé de votre bien'
          name='infos.nom'
          useForm={inputForm}
          error={errors?.infos?.nom?.message}
        />
        <Input label='n°/nom de rue' name='infos.rue' useForm={inputForm} error={errors?.infos?.rue?.message} />
        <div className='row-1000 mb-sm'>
          <Input label='ville' name='infos.ville' useForm={inputForm} error={errors?.infos?.ville?.message} />
          <Input
            label='code postal'
            name='infos.code_postal'
            useForm={inputForm}
            error={errors?.infos?.code_postal?.message}
          />
        </div>
        <div className='centered mt-3'>
          <p className='text-sky-blue poppins-bold'>Enregistrez jusqu'à 3 images à ajouter sur votre rapport</p>
          <p>(la première apparaîtra sur la page de garde, les suivantes sur les pages annexes)</p>
        </div>
        <div className='row-1000 gap-md mt-md space-between'>
          <FileInputPreview
            register={register}
            setValue={setValue}
            name='images.image_1'
            label='image 1'
            previewDefaultValue={images.image_1}
            onClear={onImageClear}
          />
          <FileInputPreview
            register={register}
            setValue={setValue}
            name='images.image_2'
            label='image 2'
            previewDefaultValue={images.image_2}
            onClear={onImageClear}
          />
          <FileInputPreview
            register={register}
            setValue={setValue}
            name='images.image_3'
            label='image 3'
            previewDefaultValue={images.image_3}
            onClear={onImageClear}
            {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
          />
        </div>
      </fieldset>
    </form>
  );
}

export default BienAEvaluer;
