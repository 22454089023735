import React, { useState, useEffect, useContext } from "react";
import "./ImportEstimationsFolderModal.css";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import AppContext from "../../../../../context/AppContext";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import { fetchUserEstimationsApi } from "../../../../../api/EstimationApi";
import { addEstimationToFolderApi } from "../../../../../api/FolderApi";
import { useForm } from "react-hook-form";
import Searchbar from "../../../../molecules/searchbar/Searchbar";

function ImportEstimationsModal({ folderId, getUuid, onEstimationAdded = () => {}, folder }) {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [estimations, setEstimations] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedEstimations, setSelectedEstimations] = useState([]);
  const { setModalVisible, createNotification } = useContext(AppContext);

  useEffect(() => {
    async function fetchEstimations() {
      const res = (await fetchUserEstimationsApi(getUuid())).data;

      const validatedEstimations = [
        ...res["Fonds de commerce"].filter(estimation => estimation.validated && !estimation.archived),
        ...res["Murs commerciaux"].filter(estimation => estimation.validated && !estimation.archived),
        ...res["Titres de société"].filter(estimation => estimation.validated && !estimation.archived),
      ];

      setEstimations(validatedEstimations);
    }
    fetchEstimations();
  }, [getUuid]);

  const filteredEstimations = estimations.filter(
    estimation =>
      (estimation.infos.nom && estimation.infos.nom.toLowerCase().includes(searchValue.toLowerCase())) ||
      (estimation.infos.enseigne && estimation.infos.enseigne.toLowerCase().includes(searchValue.toLowerCase())),
  );

  function toggleEstimationSelection(estimationId) {
    if (isEstimationInFolder(estimationId)) {
      return;
    }
    if (selectedEstimations.includes(estimationId)) {
      setSelectedEstimations(selectedEstimations.filter(id => id !== estimationId));
    } else {
      setSelectedEstimations([...selectedEstimations, estimationId]);
    }
  }

  function isEstimationInFolder(estimationId) {
    return folder.estimations.some(estimation => estimation.id === estimationId);
  }

  async function handleImportEstimations() {
    try {
      const estimationsToAdd = selectedEstimations.filter(estimationId => !isEstimationInFolder(estimationId));

      if (estimationsToAdd.length === 0) {
        createNotification(<>Aucune nouvelle estimation à ajouter</>, "var(--yellow)", "var(--dark-blue)");
        return;
      }

      await Promise.all(estimationsToAdd.map(estimationId => addEstimationToFolderApi(folderId, estimationId)));

      onEstimationAdded();
      createNotification(<>Les estimations ont été ajoutées avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'ajout des estimations</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(handleImportEstimations)}>
      <div className='import-estimation-checkbox-header'>
        <h3>Liste des estimations (terminées)</h3>
        <p>Sélectionner les estimations à importer dans votre dossier.</p>
      </div>
      <Searchbar
        onChange={value => setSearchValue(value)}
        placeholder='Rechercher une estimation...'
        bgColor='var(--white)'
        className='import-estimation-searchbar'
      />
      <div className='import-estimations-list-container'>
        {filteredEstimations.length ? (
          filteredEstimations.map(estimation => {
            const isInFolder = isEstimationInFolder(estimation?.id);
            return (
              <Checkbox
                key={estimation?.id}
                label={estimation?.infos?.nom || estimation?.infos?.enseigne}
                name={`estimation-${estimation.id}`}
                onChange={() => toggleEstimationSelection(estimation?.id)}
                className='import-estimation-checkbox'
                useForm={{ setValue }}
                errors={errors}
                checked={selectedEstimations.includes(estimation?.id)}
                disabled={isInFolder}
              />
            );
          })
        ) : (
          <div className='import-estimation-no-estimation'>
            <p>Aucune estimation trouvée</p>
          </div>
        )}
      </div>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' onClick={handleImportEstimations}>
          Importer
        </FilledButton>
      </div>
    </form>
  );
}

export default ImportEstimationsModal;
