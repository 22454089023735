import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput.js";
import { ParcoursContext } from "../../../../../context/ParcoursContext.js";
import { formatObjectForPosting } from "../../../../../utils/Utils.js";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto.js";
import AppContext from "../../../../../context/AppContext.js";

function ChargesRedevance({ id, index, secondaryParcours }) {
  const {
    register,
    handleSubmit,
    unregister,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const {
    handleTabs,
    getStepToGo,
    setGoToStep,
    moveToNextStep,
    step,
    setStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);
  const { showTooltip } = useContext(AppContext);

  const inputForm = { watch, register, setValue, unregister };

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["redevance", "resultat_net"], setValue, estimationValues);
  }, [estimationValues]);

  async function formSubmit(values) {
    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <p className='text-sky-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2 className='flex gap-sm'>
          autres charges et redevances
          <Tuto onClick={() => showTooltip("step_6_redevance.webp")} />
        </h2>
        <NumberInput
          useForm={inputForm}
          name='redevance.redevance'
          error={errors?.redevance?.redevance?.message}
          icon='euro'
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2 className='flex gap-sm'>
          résultat net <Tuto onClick={() => showTooltip("step_6_resultat_net_comptable.webp")} />
        </h2>
        <NumberInput
          useForm={inputForm}
          name='resultat_net.resultat_net_comptable'
          error={errors?.resultat_net?.resultat_net_comptable?.message}
          icon='euro'
          {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
        />
      </fieldset>
    </form>
  );
}

export default ChargesRedevance;
