import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Radio from "../../../../molecules/formComponents/radio/Radio";
import Select from "../../../../molecules/formComponents/select/Select";
import Info from "../../../../atoms/icons/general/info/Info";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea.js";
import { fetchDropdownValuesApi } from "../../../../../api/ParcoursApi.js";
import { formatObjectForPosting, getSelectValue } from "../../../../../utils/Utils.js";
import { useWindowSize } from "@uidotdev/usehooks";
import ZoneInfluence from "../../../../atoms/parcours/zoneInfluence/ZoneInfluence.js";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto.js";
import { max2250Validation } from "../../../../../utils/formValidation/FormValidation.js";
import AppContext from "../../../../../context/AppContext.js";

function SituationGeographique({ index }) {
  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();
  const inputForm = { register, setValue, unregister };
  const [dropdownsValues, setDropdownsValues] = useState({});
  const {
    handleTabs,
    step,
    setStep,
    moveToNextStep,
    submitFormsMc,
    postValues,
    getStepToGo,
    setGoToStep,
    fetchDropdownValues,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);
  const { width } = useWindowSize();
  const { showTooltip } = useContext(AppContext);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues, dropdownsValues]);

  useEffect(() => {
    fillDropdownsValues();
  }, []);

  useEffect(() => {
    fillInputsFromEstimationValues(["situation_geographique"], setValue, estimationValues);
    setValue("notes.note_step_2", estimationValues?.notes?.note_step_2);
  }, [estimationValues, dropdownsValues]);

  async function formSubmit(values) {
    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    setDropdownsValues(await fetchDropdownValues("murs_qualite_secteur", "qualite_implantation_rue"));
  }

  return (
    <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <fieldset className='borderless-fieldset'>
        <h2>situation géographique</h2>
        <Radio
          padding='2rem'
          label={
            <>
              zone d’influence de l’exploitation occupant (ou ayant occupé) le local
              <Tuto onClick={() => showTooltip("step_8_type_ville.webp")} />
            </>
          }
          useForm={inputForm}
          name='situation_geographique.zone_influence.id'
          className='zone-influence-radio'
          error={errors?.situation_geographique?.zone_influence?.id?.message}
          content={[
            {
              label: (
                <>
                  {width > 1200 && <br />}
                  Rayonnement local
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />}
                  Très petite ville et village
                  <br /> (-5.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='xs' className='parcours-zone-influence' />
                </>
              ),
              value: 1,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />}
                  multicommunal
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />} Petite ville {width > 1200 && <br />}
                  (5.000 à 20.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='sm' className='parcours-zone-influence' />
                </>
              ),
              value: 2,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />}
                  départemental
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />} Ville moyenne {width > 1200 && <br />}
                  (20.000 à 100.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='lg' className='parcours-zone-influence' />
                </>
              ),
              value: 3,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />} régional&nbsp;ou&nbsp;+
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />} Grande ville {width > 1200 && <br />}(+100.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='xl' className='parcours-zone-influence' />
                </>
              ),
              value: 4,
            },
          ]}
        />
        <Select
          useForm={inputForm}
          error={errors?.situation_geographique?.qualite_secteur?.id?.message}
          name='situation_geographique.qualite_secteur.id'
          label={
            <>
              qualité de secteur et de clientèle dans l’agglomération
              <Tuto onClick={() => showTooltip("step_8_qualite_secteur.webp")} />
            </>
          }>
          {dropdownsValues.murs_qualite_secteur?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <Select
          useForm={inputForm}
          error={errors?.situation_geographique?.qualite_implantation_rue?.id?.message}
          name='situation_geographique.qualite_implantation_rue.id'
          label={
            <>
              qualité d’implantation dans la rue
              <Tuto onClick={() => showTooltip("step_8_qualite_implantation_rue.webp")} />
            </>
          }>
          {dropdownsValues.qualite_implantation_rue?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}{" "}
        </Select>
        <Textarea
          useForm={inputForm}
          name='notes.note_step_2'
          validation={max2250Validation}
          error={errors?.notes?.note_step_2?.message}
          label='notes et remarques'
          className='mt-md'
          {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
        />
      </fieldset>
    </form>
  );
}

export default SituationGeographique;
