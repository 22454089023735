import React, { Fragment, useContext, useEffect, useState } from "react";
import CoverPdf from "../coverPdf/CoverPdf";
import VisitingCard from "../../organisms/visitingCard/VisitingCard";
import { PdfContext } from "../../../context/PdfContext";
import BusinessImagesPdf from "../imagesPdf/BusinessImagesPdf";
import H3Pdf from "../h3Pdf/H3Pdf";
import H2Pdf from "../h2Pdf/H2Pdf";
import TablePdf from "../tablePdf/TablePdf";
import financialElements from "../../../uploads/PdfModels/fdc_financial_elements.json";
import { formatNumberWithSpaces } from "../../../utils/Utils";
import descriptiveElements from "../../../uploads/PdfModels/fdc_descriptive_elements.json";
import strongWeakPoints from "../../../uploads/PdfModels/fdc_strong_weak_points.json";
import DescriptiveText from "../descriptiveText/DescriptiveText";
import EstimationCalcsPdf from "../estimationCalcsPdf/EstimationCalcsPdf";
import AnnexPdf from "../annexPdf/AnnexPdf";
import StrongWeakPointsGroupPdf from "../strongWeakPointsGroupPdf/StrongWeakPointsGroupPdf";
import BarChart from "../chartPdf/BarChart";
import CirclePercentagePdf from "../circlePercentagePdf/CirclePercentagePdf";
import DoughnutChart from "../chartPdf/DoughnutChart";
import LineChart from "../chartPdf/LineChart";
import { usePdfTdsFdcContext } from "../../../context/PdfTdsFdcContext";
import NegativeValuePdf from "../negativeValuePdf/NegativeValuePdf";
import { set } from "react-hook-form";

function PdfFdc() {
  const {
    visitingCard,
    estimationData,
    formatDataForDescriptiveText,
    mainYear,
    getEnseigneName,
    rapportData,
    formatDataForStrongWeakPoints,
    AdditionalInformation,
  } = useContext(PdfContext);
  const {
    formatDataForActiviteChart,
    formatDataForFinancialTable,
    getBesoinDiplomeDescription,
    formatDataForResultatNetChart,
    formatDataForDoughnutChart,
    formatDataForActiviteTable,
    formatDataForRentabiliteChart,
    formatDataForMargeBruteChart,
    formatDataForPotentielCroissance,
    Introduction,
    ElementsFinanciersText,
    formatDataForRhTable,
    formatDataForVolumeAffairesChart,
    OneYearMessage,
  } = usePdfTdsFdcContext();

  const [financialTableData, setFinancialTableData] = useState(null);
  const [doughnutChartData, setDoughnutChartData] = useState(null);
  const [margeBruteChartData, setMargeBruteChartData] = useState(null);
  const [volumeAffairesChartData, setVolumeAffairesChartData] = useState(null);
  const [rhTableData, setRhTableData] = useState(null);
  const [resultatNetChartData, setResultatNetChartData] = useState(null);
  const [potentielCroissanceData, setPotentielCroissanceData] = useState(null);
  const [rentabiliteChartData, setRentabiliteChartData] = useState(null);
  const [activiteChartData, setActiviteChartData] = useState(null);
  const [activiteTableData, setActiviteTableData] = useState(null);
  const [strongWeakPointsData, setStrongWeakPointsData] = useState(null);
  const [descriptiveTextData, setDescriptiveTextData] = useState(null);
  const [besoinDiplomeData, setBesoinDiplomeData] = useState(null);
  const [volumeAffairesChartUrl, setVolumeAffairesChartUrl] = useState("");
  const [activiteChartUrl, setActiviteChartUrl] = useState("");
  const [rentabiliteChartUrl, setRentabiliteChartUrl] = useState("");
  const [margeBruteChartUrl, setMargeBruteChartUrl] = useState("");
  const [resultatNetChartUrl, setResultatNetChartUrl] = useState("");
  const [doughnutChartUrl, setDoughnutChartUrl] = useState("");
  const [userNote, setUserNote] = useState(null);
  const [financialDataNote, setFinancialDataNote] = useState(null);

  useEffect(() => {
    formatDataForFinancialTable(estimationData, rapportData, financialElements, setFinancialTableData);
  }, [estimationData, financialElements, rapportData]);

  useEffect(() => {
    formatDataForStrongWeakPoints(rapportData?.forces_faiblesses, strongWeakPoints, setStrongWeakPointsData);
  }, [rapportData, strongWeakPoints]);

  useEffect(() => {
    formatDataForDescriptiveText(mainYear, descriptiveElements, setDescriptiveTextData);
  }, [mainYear, descriptiveElements]);

  useEffect(() => {
    formatDataForDoughnutChart(rapportData, setDoughnutChartData);
  }, [rapportData]);

  useEffect(() => {
    formatDataForPotentielCroissance(mainYear, rapportData, setPotentielCroissanceData);
  }, [mainYear, rapportData]);

  useEffect(() => {
    formatDataForRentabiliteChart(estimationData, rapportData, setRentabiliteChartData);
    formatDataForMargeBruteChart(estimationData, rapportData, setMargeBruteChartData);
    formatDataForActiviteChart(estimationData, rapportData, setActiviteChartData);
    formatDataForVolumeAffairesChart(estimationData, rapportData, setVolumeAffairesChartData);
  }, [estimationData, rapportData]);

  useEffect(() => {
    formatDataForResultatNetChart(estimationData, setResultatNetChartData);
    getBesoinDiplomeDescription(estimationData[0]);

    if (estimationData && estimationData[0]) {
      setBesoinDiplomeData(getBesoinDiplomeDescription(estimationData[0]));
    }
  }, [estimationData]);

  useEffect(() => {
    if (activiteChartData) formatDataForActiviteTable(activiteChartData, setActiviteTableData);
  }, [activiteChartData]);

  useEffect(() => {
    formatDataForRhTable(mainYear, setRhTableData);
    setUserNote(mainYear?.notes?.note_step_13);
    setFinancialDataNote(mainYear?.notes?.note_step_7);
  }, [mainYear]);

  return (
    <>
      <div>
        <CoverPdf />
        <div page-break='true' />
        <BusinessImagesPdf />
        <div page-break='true' />
        {/* Visiting card */}
        <>
          <VisitingCard {...visitingCard} />
          {visitingCard?.mentions && (
            <div className='pdf-mt pdf-text-container'>
              <p className='pdf-bold'>Mentions légales</p>
              <p>{visitingCard.mentions}</p>
            </div>
          )}
          <div page-break='true' />
        </>
        {/* Introduction */}
        {Introduction}
        {/* Financial elements */}
        <>
          <H2Pdf title='Les éléments financiers' number={1} className='pdf-mb' />
          {financialTableData && <TablePdf data={financialTableData[0]} className='fdc-financial-table' />}
          {financialTableData && (
            <TablePdf data={financialTableData[1]} isHeadFullLine className='fdc-financial-table-2' />
          )}
          {ElementsFinanciersText}
          {financialDataNote && (
            <>
              <div possible-page-break='true' />
              <div className='pdf-text-container'>
                <p>{financialDataNote}</p>
              </div>
            </>
          )}
          <div page-break='true' />
        </>
        {/* Exploitation factors */}
        <>
          <H2Pdf title='Les éléments d’exploitation' number={2} />
          {descriptiveTextData?.map(section => (
            <Fragment key={section.id}>
              <H3Pdf title={section.title} icon={section.icon} />
              <DescriptiveText data={section.content} />
              {section.conclusion && (
                <div className='pdf-text-container pdf-mb-sm'>
                  <p>{section.conclusion}</p>
                </div>
              )}
            </Fragment>
          ))}
          <div className='pdf-text-container'>
            <p>{besoinDiplomeData}</p>
          </div>
          <div page-break='true' />
        </>
        {/* RH detail */}
        {rhTableData && (
          <>
            <H3Pdf title='Ressources humaines' />
            <TablePdf hasTh={false} data={rhTableData} />
            <div className='rh-table-legend pdf-mt'>
              <div /> Hommes clefs
            </div>
            <div page-break='true' />
          </>
        )}
        {/* User note */}
        {userNote && (
          <>
            <H3Pdf title="Notes de l'utilisateur" />
            <div className='pdf-text-container'>
              <p>{userNote}</p>
            </div>
            <div page-break='true' />
          </>
        )}
        {/* Estimation details */}
        <H2Pdf title="L'estimation" number={3} className='pdf-mb' />
        {rapportData?.calcs.type === "negative" ? (
          <NegativeValuePdf />
        ) : (
          <>
            <div className='pdf-text-container'>
              <p>
                Sur la base de l'analyse de l'ensemble des éléments d'exploitation, nous évaluons{" "}
                <span className='pdf-bold'>le fonds de commerce de {getEnseigneName(mainYear)}</span> à :
              </p>
            </div>
            <div className='estimation-result-container pdf-vertical-margin'>
              <p className='value'>{formatNumberWithSpaces(rapportData?.calcs.valeur_fdc.mid_amount, true, "€")}</p>
            </div>
            <div className='pdf-text-container pdf-mb'>
              <p>{rapportData?.calcs.valeur_fdc.title}</p>
            </div>
            <EstimationCalcsPdf data={rapportData?.calcs.valeur_calcul_fdc} />
            <div page-break='true' />
          </>
        )}
        {/* Annexes */}
        <>
          <AnnexPdf
            subtitles={[
              "Méthodes d'estimation",
              "Points forts et points faibles de l'affaire",
              "Graphiques",
              "Informations complémentaires",
            ]}
          />
          <div page-break='true' />
        </>
        {/* Estimation methods */}
        <>
          <H2Pdf
            title="Méthodes d'estimation : comprendre votre estimation"
            importanceLevel={2}
            number={4}
            className='pdf-mb'
          />
          <div className='pdf-text-container'>
            <p className='pdf-bold'>
              Les fourchettes de valeur indiquées dans l'estimation sont calculées à partir de la totalité des éléments
              financiers et des facteurs d'exploitation.
            </p>
            <p className='pdf-bold'>
              Chacun de ces facteurs impacte indépendamment l'évaluation globale en fonction de son importance.
            </p>
          </div>
          <H3Pdf title='La moyenne du chiffre d’affaire annuel HT' />
          <div className='pdf-text-container'>
            <p>
              (1) La moyenne des chiffres d'affaires HT traduit le volume d'affaires que l'exploitation a été (N-1 et
              N-2) ou sera (N et N+1) capable de réaliser. Cet indicateur-phare est soumis à un coefficient
              multiplicateur dépendant à la fois du type d'activité et de l'ensemble des facteurs (implantation,
              attributs, ratios-clés, etc.), qui jouent un rôle pondérateur (à la hausse ou à la baisse, selon).
            </p>
          </div>
          {rapportData?.calcs.type !== "ebe_only" && (
            <>
              <H3Pdf title='La moyenne de la rentabilité retraitée' />
              <div className='pdf-text-container'>
                <p>
                  (2) La moyenne des EBE retraités traduit la moyenne des rentabilités réelles annuelles dégagées par
                  l'exploitation, et donc sa finançabilité. L'on adopte ainsi l'approche d'un repreneur potentiel, qui
                  devra à la fois financer la reprise du fonds et dégager une rémunération pour vivre de son travail.
                  Cet indicateur essentiel est soumis à un coefficient multiplicateur spécifique à chaque activité et
                  ajusté par l'ensemble des facteurs pondérateurs.
                </p>
              </div>
              <H3Pdf title='Le taux de profitabilité' />
              <div className='pdf-text-container'>
                <p>
                  (3) Le taux de profitabilité met en perspective la rentabilité moyenne de l'affaire (EBE retraité) par
                  rapport à son chiffre d'affaires moyen et détermine l'attractivité financière de l'affaire. On
                  valorise donc mieux une affaire dégageant une meilleure rentabilité qu'une autre affaire à chiffre
                  d'affaires égal, ou dégageant une rentabilité égale pour un chiffre d'affaires inférieur.
                </p>
              </div>
            </>
          )}
          <div possible-page-break='true' />
          <H3Pdf title='Les stocks' />
          <div className='pdf-text-container'>
            <p>
              A la valeur finale du fonds de commerce, il faut ajouter la valeur des stocks réels au jour de la cession.
              Ils ne sont pas compris dans le fonds de commerce.
            </p>
          </div>
          <H3Pdf title='La moyenne de rentabilité retraitée' />
          <div className='pdf-text-container'>
            <p>
              Dans un projet de cession de l'affaire, il est fondamental de prendre en compte différentes approches
              complémentaires. La valeur finale (prix effectif de cession) est le point de rencontre entre cédant et
              repreneur (et ses conseils comptables juridiques et financiers, dont l'avis compte dans la validation du
              prix).
            </p>
            <p>
              Dans un projet autre qu'une transaction, l'exploitant, bien que conseillé et encadré par des normes
              fiscales et de bon sens, reste seul décisionnaire de la valeur de son bien et se situera plus librement
              dans les fourchettes de valeurs.
            </p>
          </div>
          <div page-break='true' />
        </>
        {/* Strong / weak points */}
        <>
          <H2Pdf importanceLevel={2} title="Points forts et points faibles de l'affaire" number={5} />
          {strongWeakPointsData?.map(category => (
            <StrongWeakPointsGroupPdf data={category} key={category.id} />
          ))}
          <div page-break='true' />
        </>
        {/* Graphs */}
        <>
          <H2Pdf importanceLevel={2} title='Graphiques' number={7} />
          <H3Pdf title="Evolution de l'activité" notOnSummary />
          {estimationData.length > 1
            ? activiteChartData && (
                <>
                  <LineChart data={activiteChartData} onImageReady={setActiviteChartUrl} />
                  {activiteChartUrl && (
                    <img
                      className='pdf-chart-container pdf-mb'
                      src={activiteChartUrl}
                      alt='Graphique en ligne pour la performance'
                    />
                  )}
                </>
              )
            : OneYearMessage}
          {activiteTableData && <TablePdf data={activiteTableData} className='pdf-mt' />}
          <div page-break='true' />
          <H3Pdf title='Potentiel de croissance évalué sur 3 ans' notOnSummary />
          {potentielCroissanceData && <CirclePercentagePdf data={potentielCroissanceData} />}
          <div className='pdf-text-container'>
            <p>
              Le potentiel de croissance moyenne estimé est conséquent et de nature à résolument motiver un repreneur
              potentiel, dans la mesure où les pistes de croissance sont clairement identifiées et réalistes.
              Considérant la perte naturelle d'une partie de la clientèle lors de la transmission, le repreneur dispose
              toutefois d'une marge de progression solide.
            </p>
          </div>
          <div page-break='true' />
          <H3Pdf title="Taux d'effort & masse salariale" notOnSummary />
          {doughnutChartData && <DoughnutChart data={doughnutChartData} onImageReady={setDoughnutChartUrl} />}
          {doughnutChartUrl && (
            <img className='pdf-chart-container' src={doughnutChartUrl} alt='Graphique en donut pour la performance' />
          )}
          <div className='pdf-text-container pdf-mt'>
            <p className='pdf-bold'>Le taux d'effort</p>
            <p>
              Il représente le poids de la charge locative globale (loyers + charges + taxe foncière + droits de voirie)
              par rapport au chiffre d'affaires que réalise l'exploitation.
              <br />
              En fonction de la dépendance de l'activité à un local commercial, cet indicateur peut être un facteur
              décisionnel important pour le repreneur potentiel, car le loyer est une charge incompressible d'une part,
              et en hausse constante d'autre part.
            </p>
            <p className='pdf-bold'>Le taux de masse salariale</p>
            <p>
              Il représente le poids des salaires (salaires bruts + charges sociales) des employés par rapport au
              chiffre d'affaires que réalise l'exploitation.
              <br />
              C'est un facteur décisionnel important pour le repreneur potentiel, qui devra prévoir une masse salariale
              cohérente pour réaliser un chiffre d'affaires au moins équivalent au vôtre, en tenant compte du poste
              qu'il occupera, de son expérience et de ses compétences.
            </p>
          </div>
          <div page-break='true' />
          <H3Pdf title="L'évolution du volume d'affaires" notOnSummary />

          {estimationData.length > 1
            ? volumeAffairesChartData && (
                <>
                  <BarChart data={volumeAffairesChartData} onImageReady={setVolumeAffairesChartUrl} />
                  {volumeAffairesChartUrl && (
                    <img
                      className='pdf-chart-container'
                      src={volumeAffairesChartUrl}
                      alt="Graphique en barre pour l'indication de performance"
                    />
                  )}
                </>
              )
            : OneYearMessage}
          <div className='pdf-text-container pdf-mt'>
            <p className='pdf-bold'>
              L'évolution du volume d'affaires est un indicateur qui permet au repreneur potentiel de mesurer :
            </p>
            <ul className='dots-list'>
              <li>
                Dans un cas (favorable) la pérennité, la stabilité ou la progression constante du volume d'activité de
                l'affaire, ce qui aura tendance à le rassurer, ainsi que ses partenaires financiers.
              </li>
              <li>
                Dans un autre cas (défavorable) la volatilité, la progression négative ou inégale du volume d'activité
                de l'affaire, ce qui aura tendance à l'alerter, ainsi que ses partenaires financiers, sur la gestion de
                l'exploitation.
              </li>
            </ul>
          </div>
          <div page-break='true' />
          <H3Pdf title="L'évolution de la marge brute" notOnSummary />
          {estimationData.length > 1
            ? margeBruteChartData && (
                <>
                  <BarChart data={margeBruteChartData} onImageReady={setMargeBruteChartUrl} />
                  {margeBruteChartUrl && (
                    <img
                      className='pdf-chart-container'
                      src={margeBruteChartUrl}
                      alt="Graphique en barre pour l'indication de performance"
                    />
                  )}
                </>
              )
            : OneYearMessage}
          <div className='pdf-text-container pdf-mt'>
            <p className='pdf-bold'>
              L'évolution de la marge brute est un indicateur qui permet au repreneur potentiel de mesurer :
            </p>
            <ul className='dots-list'>
              <li>
                Dans un cas (favorable) apprécier la bonne gestion des coûts marchandises et matières premières dans la
                durée, ainsi que les fluctuations de ces coûts. La stabilité ou la progression constante de la marge
                brute aura tendance à le rassurer, ainsi que ses partenaires financiers.
              </li>
              <li>
                Dans un autre cas (défavorable) s'interroger sur la gestion passive (ou mauvaise) des coûts marchandises
                et matières premières dans la durée et sur la possibilité et la manière d'y apporter des correctifs. La
                progression négative ou inégale de la marge brute aura tendance à l'alerter, ainsi que ses partenaires
                financiers, sur la gestion de l'exploitation ou la volatilité de l'activité même.
              </li>
            </ul>
          </div>
          <div page-break='true' />
          <H3Pdf title="L'évolution de la rentabilité (EBE retraité)" notOnSummary />
          {estimationData.length > 1
            ? rentabiliteChartData && (
                <>
                  <LineChart data={rentabiliteChartData} maxHeight='500px' onImageReady={setRentabiliteChartUrl} />

                  {rentabiliteChartUrl && (
                    <img
                      className='pdf-chart-container'
                      src={rentabiliteChartUrl}
                      alt="Graphique en ligne pour l'évolution de la rentabilité"
                    />
                  )}
                </>
              )
            : OneYearMessage}
          <div className='pdf-text-container pdf-mt'>
            <p className='pdf-bold'>
              L'évolution de la rentabilité retraitée et de sa proportion par rapport au volume d'affaires sont des
              indicateurs qui permettent au repreneur potentiel de :
            </p>
            <ul className='dots-list'>
              <li>
                Dans un cas (favorable) mesurer la bonne maîtrise de l'ensemble des coûts dans la durée, mais aussi les
                fluctuations harmonieuses de la rentabilité par rapport aux fluctuations de chiffre d'affaires. La
                stabilité ou la progression constante de l'EBE retraité et de son taux de rentabilité est un gage de
                solidité.
              </li>
              <li>
                Dans un cas (défavorable) s'interroger sur la maîtrise passive (ou mauvaise) de l'ensemble des coûts et
                sur la possibilité et la manière d'y apporter des correctifs. Les fluctuations erratiques ou négatives
                du taux de rentabilité par rapport au chiffre d'affaires sont pour le repreneur un facteur d'alerte sur
                la gestion de l'exploitation ou la volatilité de l'activité-même.
              </li>
            </ul>
          </div>
          <div page-break='true' />
          <H3Pdf title="L'évolution du résultat net" notOnSummary />
          {estimationData.length > 1
            ? resultatNetChartData && (
                <>
                  <BarChart data={resultatNetChartData} onImageReady={setResultatNetChartUrl} />
                  {resultatNetChartUrl && (
                    <img
                      className='pdf-chart-container'
                      src={resultatNetChartUrl}
                      alt="Graphique en barre pour l'indication de performance"
                    />
                  )}
                </>
              )
            : OneYearMessage}
          <div className='pdf-text-container pdf-mt'>
            <p className='pdf-bold'>
              L'évolution du résultat net et de son taux par rapport au volume d'affaires sont des indicateurs qui
              permettent au repreneur potentiel de :
            </p>
            <ul className='dots-list'>
              <li>Mesurer la performance globale comptable de l'entreprise avant retraitements.</li>
              <li>Evaluer les montant des distributions possibles de dividendes.</li>
              <li>Jauger le montant d'imposition moyen.</li>
              <li>
                Anticiper des stratégies fiscales en considérant les bénéfices ou déficits reportables au passif du
                bilans.
              </li>
            </ul>
          </div>
          <div page-break='true' />
        </>
        {/* Additional information */}
        {AdditionalInformation}
      </div>
    </>
  );
}

export default PdfFdc;
