import React, { useState, useEffect, useContext } from "react";
import "./FolderDetail.css";
import { archiveFolderApi, fetchFolderByIdApi } from "../../../../../api/FolderApi";
import AppContext from "../../../../../context/AppContext";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import Folder from "../../../../../components/atoms/icons/folder/folder/Folder";
import Deliverable from "../../../../../components/atoms/icons/folder/deliverable/Deliverable";
import FolderInfo from "./tabs/folderInfo/FolderInfo";
import FolderEstimations from "./tabs/folderEstimations/FolderEstimations";
import FolderFinancingPlan from "./tabs/folderFinancingPlan/FolderFinancingPlan";
import FolderCreditSimulation from "./tabs/folderCreditSimulation/FolderCreditSimulation";
import FolderDeliverables from "./tabs/folderDeliverables/FolderDeliverables";
import Emc from "../../../../../components/atoms/icons/header/emc/Emc";
import Rapport from "../../../../../components/atoms/icons/header/rapport/Rapport";
import Dashboard from "../../../../../components/atoms/icons/folder/dashboard/Dashboard";
import Hr from "../../../../../components/molecules/hr/Hr";
import Chart from "../../../../../components/atoms/icons/header/chart/Chart";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../../../components/molecules/banner/Banner";
import { Link } from "react-router-dom";
import ArrowSm from "../../../../../components/atoms/icons/general/arrowSm/ArrowSm";

function FolderDetail() {
  const { createNotification } = useContext(AppContext);
  const [folder, setFolder] = useState(null);
  const [selectedTab, setSelectedTab] = useState("info");
  const [folderUpdateTrigger, setFolderUpdateTrigger] = useState(0);
  const { subTabParam } = useParams();

  useEffect(() => {
    fetchFolder(subTabParam);
  }, [subTabParam, folderUpdateTrigger]);

  async function fetchFolder(folderId) {
    try {
      const res = await fetchFolderByIdApi(folderId);

      const folder = {
        ...res.data.folder,
        estimations: [
          ...res.data.folder.estimations.fonds_de_commerce,
          ...res.data.folder.estimations.murs_commerciaux,
          ...res.data.folder.estimations.titres_de_societe,
        ],
        financing_plans: [
          ...res.data.folder.financing_plans.financing_plan_fdc,
          ...res.data.folder.financing_plans.financing_plan_murs,
        ],
      };

      folder.financing_plans = folder.financing_plans.map(plan => plan.financing_plan);
      folder.credit_simulators = folder.credit_simulators.map(plan => plan.credit_simulator);

      setFolder(folder);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération du dossier. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function archiveFolder(folderId, archive) {
    try {
      await archiveFolderApi(folderId, archive);
      await fetchFolder(subTabParam);

      createNotification(<>Le dossier a été {archive ? "archivé" : "désarchivé"} avec succès</>);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors {archive ? "de l'archivage" : "du désarchivage"} du dossier. Veuillez réessayer
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <section className='container'>
      {folder && (
        <div className='folder-detail-page-container'>
          <div className='folder-sidebar'>
            <div className='folder-header-sidebar'>
              <Folder />
              <h2>{folder?.data.folder_name}</h2>
            </div>
            <Hr margin='10px 0' />
            <ul className='folder-tabs'>
              <li
                className={`folder-tab ${selectedTab === "info" ? "active" : ""}`}
                onClick={() => setSelectedTab("info")}>
                <Dashboard width='23px' />
                Contenu du dossier
              </li>
              <li
                className={`folder-tab ${selectedTab === "estimations" ? "active" : ""}`}
                onClick={() => setSelectedTab("estimations")}>
                <Emc width='23px' />
                Estimations
              </li>
              <li
                className={`folder-tab ${selectedTab === "financingPlan" ? "active" : ""}`}
                onClick={() => setSelectedTab("financingPlan")}>
                <Rapport width='23px' />
                Plans de financement
              </li>
              <li
                className={`folder-tab ${selectedTab === "creditSimulation" ? "active" : ""}`}
                onClick={() => setSelectedTab("creditSimulation")}>
                <Chart width='23px' />
                Simulations de prêt
              </li>
              <li
                className={`folder-tab ${selectedTab === "livrables" ? "active" : ""}`}
                onClick={() => setSelectedTab("livrables")}>
                <Deliverable width='23px' />
                Livrables
              </li>
            </ul>
            <div className='folder-actions'>
              <FilledButton
                bgColor='#FDB7A9'
                color='var(--dark-blue)'
                hoverColor='#FDB7A9'
                className='w-100'
                onClick={() => archiveFolder(subTabParam, !folder.data.archived)}>
                {folder.data.archived ? "Désarchiver le dossier" : "Archiver le dossier"}
              </FilledButton>
            </div>
          </div>
          <div className='folder-content'>
            {selectedTab === "info" ? (
              <FolderInfo
                folder={folder}
                triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)}
                setSelectedTab={setSelectedTab}
              />
            ) : selectedTab === "estimations" ? (
              <FolderEstimations folder={folder} triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)} />
            ) : selectedTab === "financingPlan" ? (
              <FolderFinancingPlan
                folder={folder}
                triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)}
              />
            ) : selectedTab === "creditSimulation" ? (
              <FolderCreditSimulation
                folder={folder}
                triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)}
              />
            ) : (
              <FolderDeliverables
                folder={folder}
                triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)}
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default FolderDetail;
