import React, { useContext, useEffect, useState } from "react";
import "./FolderEstimations.css";
import { useNavigate } from "react-router-dom";
import ImportEstimationsFolderModal from "../../../../../../../components/forms/modals/account/importEstimationsFolderModal/ImportEstimationsFolderModal";
import AppContext from "../../../../../../../context/AppContext";
import { deleteAllEstimationsFromFolderApi, deleteEstimationFromFolderApi } from "../../../../../../../api/FolderApi";
import { useWindowSize } from "@uidotdev/usehooks";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import Download from "../../../../../../../components/atoms/icons/general/download/Download";
import Bracket from "../../../../../../../components/atoms/icons/general/bracket/Bracket";
import Pen from "../../../../../../../components/atoms/icons/account/pen/Pen";
import Trash from "../../../../../../../components/atoms/icons/general/trash/Trash";
import UnfilledButton from "../../../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import Tuto from "../../../../../../../components/atoms/icons/general/tuto/Tuto";
import Emc from "../../../../../../../components/atoms/icons/header/emc/Emc";
import { useAuth } from "../../../../../../../context/AuthContext";
import { Link } from "react-router-dom";

function FolderEstimations({ folder, triggerFolderUpdate }) {
  const { setModalContent, setModalVisible, createNotification, showTooltip } = useContext(AppContext);
  const [openedEstimationIndex, setOpenedEstimationIndex] = useState(-1);
  const { width } = useWindowSize();
  const { getUuid } = useAuth();

  function openImportEstimationModal() {
    setModalContent({
      title: "Importer des estimations",
      content: (
        <ImportEstimationsFolderModal
          folderId={folder.data.folder_id}
          getUuid={getUuid}
          folder={folder}
          onEstimationAdded={triggerFolderUpdate}
        />
      ),
    });
    setModalVisible(true);
  }

  function toggleOpenedEstimationIndex(index) {
    setOpenedEstimationIndex(prev => (prev === index ? -1 : index));
  }

  function handleDeleteAllEstimations() {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir retirer{" "}
            {folder.estimations.length > 1 ? "toutes les estimations du dossier" : "l'estimation du dossier"} ?
            <br /> Cette action est irréversible et retirera également les estimations des livrables associés.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteAllEstimations();
              }}>
              Retirer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteAllEstimations() {
    try {
      await deleteAllEstimationsFromFolderApi(folder.data.folder_id, true);

      triggerFolderUpdate();
      createNotification(<>Toutes les estimations ont été retirées avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait des estimations</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function handleDeleteSingleEstimation(estimationId) {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir retirer cette estimation de votre dossier ?<br />
            Cette action est irréversible et retirera également l'estimation des livrables associés.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteSingleEstimation(estimationId);
              }}>
              Retirer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteSingleEstimation(estimationId) {
    try {
      await deleteEstimationFromFolderApi(folder.data.folder_id, estimationId, true);

      triggerFolderUpdate();
      createNotification(<>L'estimation a été retirée avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait de l'estimation</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <div>
      <div className='tab-content-header'>
        <h2>
          <Emc />
          Estimations
          {/* 
            <span className='tuto-icon'>
              <Tuto onClick={() => showTooltip("")} />
            </span>
          */}
        </h2>
        <p>Retrouvez ici toutes les estimations liées à votre dossier.</p>
      </div>
      <div className='folder-estimations-buttons'>
        <FilledButton to='/debut-parcours'>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer une estimation
        </FilledButton>
        <FilledButton onClick={openImportEstimationModal}>
          <Download width={width > 576 ? "30px" : "20px"} /> Importer une estimation
        </FilledButton>
      </div>
      <div className='folder-table-estimations'>
        <div className='folder-table-estimations-header'>
          <p>Nom de l'estimation</p>
          <p>Actions</p>
        </div>
        <div className='folder-table-estimations-body'>
          {folder.estimations.length ? (
            folder.estimations.map((estimation, index) => (
              <div key={estimation?.id} className='estimation-item'>
                <p>{estimation?.infos?.nom || estimation?.infos?.enseigne}</p>
                <div className='estimation-dropdown' onClick={() => toggleOpenedEstimationIndex(index)}>
                  <Bracket
                    rotation={openedEstimationIndex === index ? "0" : "180deg"}
                    width='15px'
                    color='var(--white)'
                    className='dropdown-icon-estimations'
                  />
                  <ul className={`dropdown-menu ${openedEstimationIndex === index ? "show" : ""}`}>
                    <li>
                      <Link to={`/synthese/${estimation?.id}`}>
                        <Pen width='22px' /> Modifier
                      </Link>
                    </li>
                    <li>
                      <button onClick={() => handleDeleteSingleEstimation(estimation?.id)}>
                        <Trash />
                        Retirer du dossier
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            ))
          ) : (
            <div className='info-block-estimations'>
              <p>Aucune estimation dans ce dossier</p>
            </div>
          )}
        </div>
        <div className='folder-table-estimations-footer mt-sm'>
          {folder.estimations.length > 0 && (
            <UnfilledButton bgColor='var(--dark-red)' onClick={handleDeleteAllEstimations}>
              {folder.estimations.length > 1
                ? "Retirer toutes les estimations du dossier"
                : "Retirer l'estimation du dossier"}
            </UnfilledButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default FolderEstimations;
