import React, { useRef, useEffect, useContext } from "react";
import "./Chart.css";
import Chart from "chart.js/auto";
import { formatNumberWithSpaces } from "../../../utils/Utils";
import { PdfContext } from "../../../context/PdfContext";
// data = {
//   turnover,
//   payroll,
//   rentAndCharges,
//   performanceYear,
// };
function DoughnutChart({ data, onImageReady }) {
  const canvasRef = useRef(null);
  const { config } = useContext(PdfContext);

  function formatNaNOrUndefined(value) {
    return isNaN(value) || value === undefined ? 0 : value;
  }

  useEffect(() => {
    const formattedTurnover = formatNaNOrUndefined(data.turnover);
    const formattedPayroll = formatNaNOrUndefined(data.payroll);
    const formattedRentAndCharges = formatNaNOrUndefined(data.rentAndCharges);

    const payrollPercentage = ((formattedPayroll / formattedTurnover) * 100).toFixed(2);
    const rentAndChargesPercentage = ((formattedRentAndCharges / formattedTurnover) * 100).toFixed(2);
    const remainingPercentage = (100 - payrollPercentage - rentAndChargesPercentage).toFixed(2);

    const chartConfig = {
      type: "doughnut",
      data: {
        labels: [
          `Chiffre d'affaires (${formatNumberWithSpaces(formattedTurnover, true)} €)`,
          `Masse salariale (${formatNumberWithSpaces(formattedPayroll, true)} € - ${payrollPercentage}%)`,
          `Loyer et charges (${formatNumberWithSpaces(formattedRentAndCharges, true)} € - ${rentAndChargesPercentage}%)`,
        ],
        datasets: [
          {
            label: "Chiffre d'affaires",
            data: [200],
            backgroundColor: [config.chartsColor.color_5],
            borderWidth: 4,
          },
          {
            data: [remainingPercentage, payrollPercentage, rentAndChargesPercentage],
            backgroundColor: ["rgba(255, 255, 255, 0)", config.chartsColor.color_1, config.chartsColor.color_2],
          },
        ],
      },
      options: {
        responsive: true,
        animation: {
          onComplete: () => {
            const imageUrl = canvasRef.current.toDataURL();
            onImageReady(imageUrl);
          },
        },
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            labels: {
              color: "rgb(0, 0, 0)",
              boxWidth: 30,
              padding: 20,
              generateLabels: chart => {
                const data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  const colors = [data.datasets[0].backgroundColor[0], ...data.datasets[1].backgroundColor.slice(1)];
                  return data.labels.map((label, i) => ({
                    text: label,
                    fillStyle: colors[i],
                    strokeStyle: colors[i],
                    hidden: false,
                    index: i,
                  }));
                }
                return [];
              },
            },
          },
          tooltip: {
            callbacks: {
              label: tooltipItem => {
                let label = chartConfig.data.labels[tooltipItem.dataIndex] || "";
                return label;
              },
            },
            bodyFont: {
              size: 16,
            },
          },
        },
      },
    };

    const chart = new Chart(canvasRef.current, chartConfig);

    return () => {
      chart.destroy();
    };
  }, [data, onImageReady]);

  return (
    <div className='doughnut-chart-container'>
      <canvas ref={canvasRef} style={{ maxWidth: "650px", maxHeight: "410px", display: "none" }} />
    </div>
  );
}

export default DoughnutChart;
