import { useWindowSize } from "@uidotdev/usehooks";
import AppContext from "../../../context/AppContext";
import Download from "../../atoms/icons/general/download/Download";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../molecules/buttons/unfilledButton/UnfilledButton";
import "./PracticalTool.css";
import React, { useContext } from "react";

function PracticalTool({ tool, isUserSub }) {
  const { setModalContent, setModalVisible } = useContext(AppContext);
  const { width } = useWindowSize();

  function showSubModal() {
    setModalContent({
      title: tool.name,
      content: <p>Profitez de tous nos outils pratiques avec l'offre la mieux adaptée à vos besoins</p>,
      buttons: (
        <>
          <UnfilledButton onClick={() => setModalVisible(false)} padding='15px 25px'>
            Annuler
          </UnfilledButton>
          <a
            className='filled-button button default-button-settings'
            style={{ "--padding": "15px 20px" }}
            href='/tarifs'>
            Voir les offres
          </a>
        </>
      ),
    });
    setModalVisible(true);
  }

  return (
    <div className='practical-tool-container'>
      <p className='practical-tool-description' dangerouslySetInnerHTML={{ __html: tool.content }}></p>
      <div className='practical-tool-preview-container'>
        {isUserSub ? (
          <a
            className='button filled-button default-button-settings'
            download={tool.file_name}
            href={`${process.env.PUBLIC_URL}/assets/OutilsPratiques/${tool.article_url}/file/${tool.file_name}`}>
            Télécharger
            <Download className='clickable-icon' />
          </a>
        ) : (
          <FilledButton padding='10px 20px' onClick={showSubModal}>
            Télécharger
            <Download className='clickable-icon' />
          </FilledButton>
        )}
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `${process.env.REACT_APP_API_URL}/assets/Misc/no_image.svg`;
            currentTarget.alt = "Image non disponible";
          }}
          src={`${process.env.REACT_APP_API_URL}/assets/OutilsPratiques/${tool.article_url}/preview/${tool.img_ex}`}
          alt={tool.previewAlt}
        />
      </div>
    </div>
  );
}

export default PracticalTool;
