import { useNavigate } from "react-router-dom";
import "./AccountToolsTab.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { archiveFinancingPlanApi } from "../../../../../api/FinancingPlanApi";
import { archiveCreditSimulatorApi } from "../../../../../api/CreditSimulatorApi";
import {
  EstimationTabCreateButton,
  EstimationTabRowItem,
  EstimationsTabDropdownMenu,
  EstimationsTabFilters,
} from "./EstimationsTabComponents";
import {
  FinancingPlanTabCreateButton,
  FinancingPlanTabRowItem,
  FinancingPlansTabDropdownMenu,
  FinancingPlansTabFilters,
} from "./FinancingPlansTabComponents";
import {
  CreditSimulatorCreateButton,
  CreditSimulatorsTabDropdownMenu,
  CreditSimulatorsTabFilters,
  CreditSimulatorTabRowItem,
} from "./CreditSimulatorsTabComponents";
import AppContext from "../../../../../context/AppContext";
import { useAuth } from "../../../../../context/AuthContext";
import { useWindowSize } from "@uidotdev/usehooks";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../components/atoms/icons/general/add/Add";
import Searchbar from "../../../../../components/molecules/searchbar/Searchbar";
import Pen from "../../../../../components/atoms/icons/account/pen/Pen";
import { Link } from "react-router-dom";
import Download from "../../../../../components/atoms/icons/general/download/Download";
import Duplicate from "../../../../../components/atoms/icons/account/duplicate/Duplicate";
import Unarchive from "../../../../../components/atoms/icons/account/unarchive/Unarchive";
import Archive from "../../../../../components/atoms/icons/account/archive/Archive";
import Bracket from "../../../../../components/atoms/icons/general/bracket/Bracket";
import ComponentsRow from "../../../../../components/organisms/componentsRow/ComponentsRow";
import DuplicateEstimationModal from "../../../../../components/forms/modals/account/duplicateEstimationModal/DuplicateEstimationModal";
import DuplicateFinancingPlanModal from "../../../../../components/forms/modals/account/duplicateFinancingPlanModal/DuplicateFinancingPlanModal";
import DuplicateCreditSimulatorModal from "../../../../../components/forms/modals/account/duplicateCreditSimulatorModal/DuplicateCreditSimulator";
import { get } from "react-hook-form";
import { normalizeString } from "../../../../../utils/Utils";
import { archiveEstimationApi } from "../../../../../api/EstimationApi";
import Loader from "../../../../../components/organisms/loader/Loader";

//type = estimations / financingPlans / creditSimulators
function AccountToolsTab({ type, items, refreshItems = () => {}, isLoaderVisible }) {
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [visibleItems, setVisibleItems] = useState([]);
  const [openedItemDropdown, setOpenedItemDropdown] = useState(false);
  const [itemsContainerScroll, setItemsContainerScroll] = useState(0);
  const [resetSearchValueTrigger, setResetSearchValueTrigger] = useState(false);
  const { createNotification, setModalContent, setModalVisible } = useContext(AppContext);
  const { getUuid } = useAuth();
  const navigate = useNavigate();
  const itemsContainerRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const itemsComponents = {
    filters: {
      estimations: EstimationsTabFilters,
      financingPlans: FinancingPlansTabFilters,
      creditSimulators: CreditSimulatorsTabFilters,
    },
    dropdownMenu: {
      estimations: EstimationsTabDropdownMenu,
      financingPlans: FinancingPlansTabDropdownMenu,
      creditSimulators: CreditSimulatorsTabDropdownMenu,
    },
    createButton: {
      estimations: EstimationTabCreateButton,
      financingPlans: FinancingPlanTabCreateButton,
      creditSimulators: CreditSimulatorCreateButton,
    },
    rowItem: {
      estimations: EstimationTabRowItem,
      financingPlans: FinancingPlanTabRowItem,
      creditSimulators: CreditSimulatorTabRowItem,
    },
  };

  const { width } = useWindowSize();

  useEffect(() => {
    refreshItems();
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdownMenu);

    return () => {
      document.removeEventListener("click", handleCloseDropdownMenu);
    };
  }, [dropdownMenuRef.current]);

  useEffect(() => {
    if (!itemsContainerRef.current) return;

    onItemsContainerScroll();

    itemsContainerRef.current?.addEventListener("scroll", onItemsContainerScroll);

    return () => {
      itemsContainerRef.current?.removeEventListener("scroll", onItemsContainerScroll);
    };
  }, [openedItemDropdown]);

  useEffect(() => {
    setResetSearchValueTrigger(!resetSearchValueTrigger);
  }, [activeTab, type]);

  useEffect(() => {
    setVisibleItems(getFilteredItems(items, type));
  }, [activeTab, searchValue, items, type]);

  useEffect(() => {
    setOpenedItemDropdown(false);
  }, [visibleItems]);

  function onItemsContainerScroll() {
    if (!openedItemDropdown) return setItemsContainerScroll(itemsContainerRef.current.scrollTop);

    const elementOffsetBottom =
      itemsContainerRef.current.children[openedItemDropdown.key]?.offsetTop ??
      0 + itemsContainerRef.current.children[openedItemDropdown.key]?.offsetHeight ??
      0;

    const containerOffsetBottom = itemsContainerRef.current.scrollTop + itemsContainerRef.current.offsetHeight;
    let dropdownMenuHeight = 20;
    for (const child of dropdownMenuRef.current.children) dropdownMenuHeight += child.offsetHeight + 10;

    if (elementOffsetBottom + dropdownMenuHeight + 25 > containerOffsetBottom)
      setItemsContainerScroll(itemsContainerRef.current.scrollTop + dropdownMenuHeight + 15);
    else setItemsContainerScroll(itemsContainerRef.current.scrollTop);

    if (
      (openedItemDropdown && elementOffsetBottom < itemsContainerRef.current.scrollTop) ||
      elementOffsetBottom > containerOffsetBottom
    )
      setOpenedItemDropdown(false);
  }

  function handleCloseDropdownMenu(event) {
    if (!dropdownMenuRef.current) return;

    if (!dropdownMenuRef.current.contains(event.target)) {
      setOpenedItemDropdown(false);
    }
  }

  function getFilteredItems(items, type) {
    if (searchValue === "") return items[type].filter(item => item.archived === !!activeTab);

    let result = [];

    switch (type) {
      case "estimations":
        result = items.estimations.filter(
          estimation =>
            estimation.archived === !!activeTab &&
            (estimation.infos.enseigne?.toLowerCase().includes(searchValue.toLowerCase()) ||
              estimation.infos.nom?.toLowerCase().includes(searchValue.toLowerCase()) ||
              estimation.type.description.toLowerCase().includes(searchValue.toLowerCase()) ||
              estimation.infos.code_postal.toLowerCase().includes(searchValue.toLowerCase()) ||
              estimation.infos.rue.toLowerCase().includes(searchValue.toLowerCase()) ||
              estimation.infos.ville.toLowerCase().includes(searchValue.toLowerCase())),
        );
        break;
      case "financingPlans":
        result = items.financingPlans.filter(
          plan =>
            plan.archived === !!activeTab &&
            (normalizeString(plan.financing_plan_name).includes(normalizeString(searchValue)) ||
              normalizeString(plan.financing_plan_type === "fdc" ? "Fonds" : "Murs").includes(
                normalizeString(searchValue),
              )),
        );
        break;
      case "creditSimulators":
        result = items.creditSimulators.filter(
          simulator =>
            simulator.archived === !!activeTab &&
            normalizeString(simulator.credit_name).includes(normalizeString(searchValue)),
        );
    }

    setSearchValue(searchValue);

    return result;
  }

  async function archiveItem(itemId, shouldArchive) {
    const itemName = getItemsCategoryName(type);

    try {
      switch (type) {
        case "estimations":
          await archiveEstimationApi(itemId, shouldArchive);
          break;
        case "financingPlans":
          await archiveFinancingPlanApi(itemId, shouldArchive);
          break;
        case "creditSimulators":
          await archiveCreditSimulatorApi(itemId, shouldArchive);
          break;
      }

      refreshItems();
      createNotification(
        <>
          Votre {itemName} a été {shouldArchive ? "archivée" : "désarchivée"} avec succès
        </>,
      );
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors {shouldArchive ? "de l'archivage" : "du désarchivage"} de votre {itemName}.
          Veuillez réessayer
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function getDuplicateItemName(itemName) {
    const regex = /#\d+$/;

    if (regex.test(itemName)) {
      const match = itemName.match(/\d+$/);

      return itemName.replace(regex, `#${parseInt(match[0]) + 1}`);
    } else {
      return `${itemName} #1`;
    }
  }

  function getItemsCategoryName(type, plural) {
    return type === "estimations"
      ? `estimation${plural ? "s" : ""}`
      : type === "financingPlans"
        ? `plan${plural ? "s" : ""} de financement`
        : `simulation${plural ? "s" : ""} de prêt`;
  }

  function showDuplicateItemModal(itemId, itemName) {
    switch (type) {
      case "estimations":
        setModalContent({
          title: "Dupliquer l'estimation",
          content: (
            <DuplicateEstimationModal
              defaultValue={getDuplicateItemName(getEstimationName(itemId))}
              fetchEstimationsFunction={() => refreshItems()}
              estimationId={itemId}
            />
          ),
        });
        break;
      case "financingPlans":
        setModalContent({
          title: "Dupliquer le plan de financement",
          content: (
            <DuplicateFinancingPlanModal
              defaultValue={getDuplicateItemName(itemName)}
              fetchFinancingPlansFunction={() => refreshItems()}
              financingPlanId={itemId}
            />
          ),
        });
        break;
      case "creditSimulators":
        setModalContent({
          title: "Dupliquer la simulation de prêt",
          content: (
            <DuplicateCreditSimulatorModal
              defaultValue={getDuplicateItemName(itemName)}
              fetchCreditSimulatorsFunction={() => refreshItems()}
              creditSimulatorId={simulatorId}
            />
          ),
        });
    }

    setModalVisible(true);
  }

  function getEstimationName(itemId) {
    const estimation = items.estimations.find(estimation => estimation.id === itemId);
    return estimation.infos.enseigne ?? estimation.infos.nom;
  }

  function getItemDate(item) {
    switch (type) {
      case "estimations":
        return item?.date_creation_estimation?.substring(0, 10);
      case "financingPlans":
      case "creditSimulators":
        return item?.date_creation?.split(" ")[0];
    }
  }

  function toggleOpenedItemDropdown(e, key) {
    e.preventDefault();

    const tmp = openedItemDropdown;
    const top = itemsContainerRef.current.children[key].offsetTop + 60;

    if (openedItemDropdown) setOpenedItemDropdown(false);

    setTimeout(() => {
      if (tmp.key !== key)
        setOpenedItemDropdown({
          item: visibleItems[key],
          key,
          top,
        });
    }, 10);
  }

  function getTableClassName(type) {
    return `${type === "estimations" ? "estimations" : type === "financingPlans" ? "financing-plans" : "credit-simulators"}-table`;
  }

  const EstimationTumbnail = estimation => {
    return (
      <div className='item-thumbnail-container'>
        <div className='text-center'>Enseigne</div>
        <div className='item-thumbnail'>
          <div className='item-enseigne'>
            {estimation.infos?.enseigne ?? ""}
            {estimation.infos?.nom}
          </div>
          <div className='item-infos'>
            <p className='label'>Type</p>
            <p>{estimation.type.description}</p>
            <p className='label'>Adresse</p>
            <p>{`${estimation.infos.rue} ${estimation.infos.ville}`}</p>
            <p className='label'>Date</p>
            <p>{getItemDate(estimation)}</p>
          </div>
          <div className='item-buttons'>
            <Link to={`/synthese/${estimation.id}`}>
              <FilledButton color='var(--dark-blue)' bgColor='#E6CC83' hoverColor='#E6CC83'>
                <Pen width='30px' />
              </FilledButton>
              Modifier
            </Link>
            {estimation.validated ? (
              <>
                <Link href={`/pdf/${estimation.id}`} target='_blank'>
                  <div>
                    <FilledButton bgColor='#B8C7FF' hoverColor='#B8C7FF'>
                      <Download color='var(--dark-blue)' width='30px' />
                    </FilledButton>
                    Télécharger
                  </div>
                </Link>
                <div onClick={() => showDuplicateItemModal(estimation.id, estimation?.infos?.enseigne)}>
                  <FilledButton bgColor='#E3E3E3' hoverColor='#E3E3E3'>
                    <Duplicate width='30px' />
                  </FilledButton>
                  Dupliquer
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className='fake-button'>
                    <Download width='30px' color='var(--dark-blue)' />
                    <div>Estimation non terminée</div>
                  </div>
                  Télécharger
                </div>
                <div>
                  <div className='fake-button'>
                    <Duplicate width='30px' />
                    <div>Estimation non terminée</div>
                  </div>
                  Dupliquer
                </div>
              </>
            )}
            {estimation.archived ? (
              <>
                <div>
                  <FilledButton
                    bgColor='#FDB7A9'
                    hoverColor='#FDB7A9'
                    onClick={() => archiveItem(estimation.id, false)}>
                    <Unarchive width='30px' />
                  </FilledButton>
                  Désarchiver
                </div>
              </>
            ) : (
              <div>
                <FilledButton bgColor='#FDB7A9' hoverColor='#FDB7A9' onClick={() => archiveItem(estimation.id, true)}>
                  <Archive width='30px' />
                </FilledButton>
                Archiver
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className='container'>
        {itemsComponents.createButton[type]({ width })}
        <div className='items-container'>
          <div className='items-container-header'>
            <p className={activeTab === 0 ? "active" : ""} onClick={() => setActiveTab(0)}>
              Mes {getItemsCategoryName(type, 1)}
            </p>
            <p className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>
              Mes {getItemsCategoryName(type, 1)} archivées
            </p>
          </div>
          <div className='search-input-container'>
            <Searchbar
              resetValueTrigger={resetSearchValueTrigger}
              onChange={value => setSearchValue(value)}
              placeholder='Rechercher'
              bgColor={width > 1200 ? "var(--white)" : "var(--beige)"}
              className='items-search-bar'
            />
          </div>
          {width > 1200 && (
            <div className={`items-table ${getTableClassName(type)}`}>
              <div className='items-filters'>{itemsComponents.filters[type]()}</div>
              <ul
                ref={dropdownMenuRef}
                className={`dropdown-menu-item ${openedItemDropdown ? "show" : ""}`}
                style={{ "--top": openedItemDropdown?.top - itemsContainerScroll + "px" }}>
                {itemsComponents.dropdownMenu[type]({ openedItemDropdown, showDuplicateItemModal, archiveItem })}
              </ul>
              <div className='items-table-body' ref={itemsContainerRef}>
                {visibleItems.map((item, key) =>
                  itemsComponents.rowItem[type]({
                    key,
                    item,
                    date: getItemDate(item),
                    toggleOpenedItemDropdown,
                    openedItemDropdown,
                  }),
                )}
                {!visibleItems.length && !isLoaderVisible && (
                  <p className='text-lg outfit-semibold my-md text-center'>
                    Aucun résultat ne correspond à votre recherche
                  </p>
                )}
                <Loader visible={isLoaderVisible} className='tools-loader' preventScrolling={false} />
              </div>
            </div>
          )}
        </div>
      </section>
      {/* {width <= 1200 && (
        <>
          <ComponentsRow
            slideLength={width > 576 ? 1 : 0}
            contentType='estimations'
            Component={EstimationTumbnail}
            componentsProps={visibleItems}
          />
        </>
      )} */}
    </>
  );
}

export default AccountToolsTab;
