import { useNavigate, useSearchParams } from "react-router-dom";
import Banner from "../../../components/molecules/banner/Banner";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import FilledButton from "../../../components/molecules/buttons/filledButton/FilledButton";
import "./Login.css";
import React, { useContext, useEffect } from "react";
import { useAuth } from "../../../context/AuthContext";
import LoginForm from "../../../components/forms/account/loginForm/LoginForm";
import AppContext from "../../../context/AppContext";

function Login() {
  const { getUuid } = useAuth();
  const { createNotification } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Display notification if user is redirected by axios config
  useEffect(() => {
    switch (searchParams.get("state")) {
      case "bad_credentials":
        createNotification(<>Votre session a expiré. Veuillez vous reconnecter.</>);
        break;
      case "account_reactivation":
        createNotification(<>Votre compte a été réactivé avec succès, vous pouvez maintenant vous reconnecter.</>);
        break;
    }

    searchParams.delete("state");
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    if (getUuid() && !localStorage.getItem("account_not_activated")) navigate("/mon-compte/mes-outils/mes-estimations");
  }, [getUuid()]);

  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            { name: "Accueil", to: "/" },
            { name: "Connexion", to: "/connexion" },
          ]}
        />
        <Banner title='Connexion' subtitle='Bienvenue, connectez-vous afin de profiter de nos offres' />
        <div className='connexion-page-container'>
          <LoginForm />
          <div>
            <h2 className='h2-uppercase'>Pas encore de compte ? </h2>
            <p>
              L'inscription à notre site vous permet d'accéder à toutes ses fonctionnalités et à l'historique de vos
              commandes. Nous allons créer un nouveau compte pour vous en un instant, il vous suffit de renseigner
              quelques informations nécessaires.
            </p>
            <div className='centered mt-md'>
              <FilledButton padding='10px 25px' to='/inscription'>
                Créer mon compte
              </FilledButton>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
