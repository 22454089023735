import { Link } from "react-router-dom";
import Archive from "../../../../../components/atoms/icons/account/archive/Archive";
import Duplicate from "../../../../../components/atoms/icons/account/duplicate/Duplicate";
import Unarchive from "../../../../../components/atoms/icons/account/unarchive/Unarchive";
import Download from "../../../../../components/atoms/icons/general/download/Download";
import Pen from "../../../../../components/atoms/icons/account/pen/Pen";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../components/atoms/icons/general/add/Add";
import Bracket from "../../../../../components/atoms/icons/general/bracket/Bracket";

export const EstimationsTabFilters = () => (
  <>
    <div>Enseigne</div>
    <div>Type</div>
    <div>Adresse</div>
    <div>Date</div>
    <div>Actions</div>
  </>
);

export const EstimationsTabDropdownMenu = ({
  openedItemDropdown,
  showDuplicateItemModal = () => {},
  archiveItem = () => {},
}) => (
  <>
    <li>
      <Link to={`/synthese/${openedItemDropdown?.item?.id}`}>
        <Pen width='18px' />
        Modifier
      </Link>
    </li>
    {openedItemDropdown?.item?.validated ? (
      <>
        <li>
          <Link to={`/pdf/${openedItemDropdown?.item?.id}`} target='_blank'>
            <Download color='var(--dark-blue)' width='18px' />
            Télécharger
          </Link>
        </li>
        <li>
          <button onClick={() => showDuplicateItemModal(openedItemDropdown?.item?.id)}>
            <Duplicate width='18px' />
            Dupliquer
          </button>
        </li>
      </>
    ) : (
      <>
        <li>
          <button className='disabled-button-dropdown-item tooltip-item' data-tooltip='Estimation non terminée'>
            <Download width='18px' color='var(--dark-blue)' />
            Télécharger
          </button>
        </li>
        <li>
          <button className='disabled-button-dropdown-item tooltip-item' data-tooltip='Estimation non terminée'>
            <Duplicate width='18px' />
            Dupliquer
          </button>
        </li>
      </>
    )}
    {openedItemDropdown?.item?.archived ? (
      <li>
        <button onClick={() => archiveItem(openedItemDropdown?.item?.id, false)}>
          <Unarchive width='18px' />
          Désarchiver
        </button>
      </li>
    ) : (
      <li>
        <button onClick={() => archiveItem(openedItemDropdown?.item?.id, true)}>
          <Archive width='18px' />
          Archiver
        </button>
      </li>
    )}
  </>
);

export const EstimationTabCreateButton = ({ width }) => (
  <FilledButton to='/debut-parcours' className='mid-page-button items-button'>
    <Add width={width > 576 ? "30px" : "20px"} /> Démarrer une nouvelle estimation
  </FilledButton>
);

export const EstimationTabRowItem = ({ key, item, date, toggleOpenedItemDropdown = () => {}, openedItemDropdown }) => (
  <Link className='item-overview-container' key={key} to={`/synthese/${item.id}`}>
    <div>
      {item.id} {item.infos?.enseigne}
      {item.infos?.nom}
    </div>
    <div>{item.type?.description.split(" ")[0]}</div>
    <div>{`${item.infos?.rue} ${item.infos?.ville}`}</div>
    <div>{date}</div>
    <div className='item-filters-buttons' onClick={e => toggleOpenedItemDropdown(e, key)}>
      <Bracket
        rotation={openedItemDropdown?.key === key ? "0" : "180deg"}
        width='14px'
        color='var(--white)'
        className='dropdown-icon-item'
      />
    </div>
  </Link>
);
