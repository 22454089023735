import React, { useRef, useEffect } from "react";
import { toggleCollapseElement } from "../../../../utils/Utils";
import "./Checkbox.css";
import Warning from "../../../atoms/icons/general/warning/Warning";
import Check from "../../../atoms/icons/general/check/Check";

// useForm = {
//   register: () => {},
//   unregister: () => {},
//   setValue: () => {},
// }
function Checkbox({
  useForm = {},
  label,
  name,
  validation = {},
  error,
  visible = true,
  onChange = () => {},
  onFocus = () => {},
  onKeyDown = () => {},
  className = "",
  checked,
  disabled = false,
}) {
  const { register = () => {}, unregister = () => {}, setValue = () => {}, watch = () => {} } = useForm;
  const ref = useRef(null);
  const inputRegister = register(name, validation);
  useEffect(() => {
    toggleCollapseElement(ref.current, visible, 0);

    if (!visible) {
      unregister(name, { keepValue: true });
    }
  }, [visible]);

  return (
    <div className={`checkbox-error-container ${className}`} ref={ref}>
      <div className='field-container checkbox-container'>
        <input
          {...(visible && register(name, validation))}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          disabled={disabled}
          id={name}
          name={name}
          type='checkbox'
          onChange={e => {
            if (disabled) return;
            setValue(name, e.target.checked);
            onChange(e);
            inputRegister && inputRegister.onChange(e);
          }}
          {...(checked !== undefined && { checked: checked })}
        />
        <label htmlFor={name} className={`checkbox-check ${disabled ? "disabled" : ""}`}>
          <Check width='10px' color={disabled ? "var(--gray)" : "var(--white)"} />
        </label>
        <label className={`form-label ${disabled ? "disabled" : ""}`} htmlFor={name}>
          {label}
        </label>
      </div>
      {error && (
        <div className='form-error'>
          <Warning /> <p>{error}</p>
        </div>
      )}
    </div>
  );
}

export default Checkbox;
