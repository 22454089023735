import { Link } from "react-router-dom";
import Pen from "../../../../../components/atoms/icons/account/pen/Pen";
import Duplicate from "../../../../../components/atoms/icons/account/duplicate/Duplicate";
import Unarchive from "../../../../../components/atoms/icons/account/unarchive/Unarchive";
import Archive from "../../../../../components/atoms/icons/account/archive/Archive";
import Add from "../../../../../components/atoms/icons/general/add/Add";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import Bracket from "../../../../../components/atoms/icons/general/bracket/Bracket";

export const CreditSimulatorsTabFilters = () => {
  return (
    <>
      <div>Nom de la simulation de prêt</div>
      <div>Date de création</div>
      <div>Actions</div>
    </>
  );
};

export const CreditSimulatorsTabDropdownMenu = ({
  openedItemDropdown,
  showDuplicateItemModal = () => {},
  archiveItem = () => {},
}) => (
  <>
    <li>
      <Link to={`/simulateur-credit/${openedItemDropdown?.item?.credit_id}`}>
        <Pen width='18px' />
        Accéder
      </Link>
    </li>
    <li>
      <button
        onClick={() =>
          showDuplicateItemModal(openedItemDropdown?.item?.credit_id, openedItemDropdown?.item?.credit_name)
        }>
        <Duplicate width='18px' />
        Dupliquer
      </button>
    </li>
    {openedItemDropdown?.item?.archived ? (
      <li>
        <button onClick={() => archiveItem(openedItemDropdown?.item?.credit_id, false)}>
          <Unarchive width='18px' />
          Désarchiver
        </button>
      </li>
    ) : (
      <li>
        <button onClick={() => archiveItem(openedItemDropdown?.item.credit_id, true)}>
          <Archive width='18px' />
          Archiver
        </button>
      </li>
    )}
  </>
);

export const CreditSimulatorCreateButton = ({ width }) => (
  <FilledButton className='mid-page-button items-button' to='/simulateur-credit'>
    <Add width={width > 576 ? "30px" : "20px"} /> Créer une simulation de prêt
  </FilledButton>
);

export const CreditSimulatorTabRowItem = ({
  key,
  item,
  date,
  toggleOpenedItemDropdown = () => {},
  openedItemDropdown,
}) => (
  <Link key={key} className='item-overview-container' to={`/simulateur-credit/${item.credit_id}`}>
    <div>{item.credit_name}</div>
    <div>{date}</div>
    <div className='item-filters-buttons' onClick={e => toggleOpenedItemDropdown(e, key)}>
      <Bracket
        rotation={openedItemDropdown?.key === key ? "0" : "180deg"}
        width='15px'
        color='var(--white)'
        className='dropdown-icon-item'
      />
      <ul className={`dropdown-menu-item ${openedItemDropdown === key ? "show" : ""}`}></ul>
    </div>
  </Link>
);
