import React, { useEffect, useState } from "react";
import FilledButton from "../../buttons/filledButton/FilledButton";
import Add from "../../../atoms/icons/general/add/Add";
import { useWindowSize } from "@uidotdev/usehooks";
import DoubleInput from "../doubleInput/DoubleInput";
import Trash from "../../../atoms/icons/general/trash/Trash";
import { removeSpaces } from "../../../../utils/Utils";

// useForm = {
//   register: () => {},
//   unregister: () => {},
//   watch: () => {},
//   setValue: () => {},
// }
function MultiplesInputs({
  className = "",
  useForm,
  name,
  placeholder,
  errors,
  updater = () => {},
  onInputChange = () => {},
  defaultValues,
  buttonText = "Ajouter une ligne",
}) {
  const { register = () => {}, unregister = () => {}, setValue = () => {}, watch = () => {} } = useForm;
  const { width } = useWindowSize();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    updater(prev => prev + 1);
  }, [rows]);

  useEffect(() => {
    fillInputs(defaultValues);
  }, [defaultValues]);

  function fillInputs(defaultValues) {
    if (!defaultValues) {
      setRows([]);
      unregister(name);
      return;
    }

    setRows(defaultValues.map((value, index) => index));

    setTimeout(() => {
      defaultValues.forEach((value, index) => {
        setValue(`${name}.row_${index}.label`, value.label);
        setValue(`${name}.row_${index}.value`, value.value);
      });
    }, 300);
  }

  function addRow() {
    const tmp = rows.reduce((acc, row) => Math.max(acc, row), -1) + 1;

    setRows(prev => [...prev, tmp]);
  }

  function deleteRow(index) {
    unregister([`${name}.row_${index}.label`, `${name}.row_${index}.value`]);

    setRows(prev => prev.filter(row => row !== index));
    onInputChange();
  }

  return (
    <div className={`multiples-inputs-container ${className}`}>
      {rows.map(row => (
        <div key={row} className='multiples-inputs-row'>
          <DoubleInput
            onChange={onInputChange}
            useForm={useForm}
            onDelete={() => deleteRow(row)}
            placeholder={placeholder}
            key={row}
            labelName={`${name}.row_${row}.label`}
            name={`${name}.row_${row}.value`}
            error={errors?.["row_" + row]?.value || errors?.["row_" + row]?.label}
          />
        </div>
      ))}
      {rows.length < 10 && (
        <FilledButton onClick={addRow} className='ml-auto mr-sm'>
          <Add width={width > 576 ? "30px" : "20px"} /> {buttonText}
        </FilledButton>
      )}
    </div>
  );
}

export default MultiplesInputs;

export function formatMultiplesInputsValues(values) {
  if (!values) return {};
  return Object.keys(values).map(key => values[key]);
}

export function calcMultiplesInputsTotal(values) {
  if (!values) return 0;

  return Object.keys(values).reduce((acc, key) => acc + removeSpaces(values[key].value), 0);
}
