import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Banner from "../../../components/molecules/banner/Banner";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import FiltersGroup from "../../../components/organisms/filtersGroup/FiltersGroup";
import AccountSubscriptionBills from "./tabs/accountSubscriptionBills/AccountSubscriptionBills";
import AccountProfile from "./tabs/accountProfile/AccountProfile";
import AccountFormations from "./tabs/accountFormations/AccountFormations";
import AccountTools from "./tabs/accountTools/AccountTools";
import AccountFolders from "./tabs/accountFolders/AccountFolders";
import AccountTeams from "./tabs/accountTeams/AccountTeams";
import FolderDetail from "./tabs/accountFolders/FolderDetail";
import FolderInfo from "./tabs/accountFolders/tabs/folderInfo/FolderInfo";
import { Link } from "react-router-dom";
import ArrowSm from "../../../components/atoms/icons/general/arrowSm/ArrowSm";

function MonCompte() {
  const [currentTab, setCurrentTab] = useState(0);
  const [pageTitle, setPageTitle] = useState("");
  const [activeTabIndexChanger, setActiveTabIndexChanger] = useState(undefined);
  const navbarRef = useRef();
  const navigate = useNavigate();
  const { tabParam, subTabParam } = useParams();
  const location = useLocation();

  useEffect(() => {
    let tab = 0;

    if (tabParam) {
      switch (tabParam) {
        case "mes-outils":
          tab = 0;
          setPageTitle("Mes outils");
          break;
        case "mes-dossiers":
          tab = 1;
          setPageTitle("Mes dossiers");
          break;
        case "mes-formations":
          tab = 2;
          setPageTitle("Mes formations");
          break;
        case "mon-equipe":
          tab = 3;
          setPageTitle("Mon équipe");
          break;
        case "mon-profil":
          tab = 4;
          setPageTitle("Mon profil");
          break;
        case "mes-abonnements-factures":
          tab = 5;
          setPageTitle("Mes abonnements & factures");
          break;
      }
    } else {
      tab = 0;
      setPageTitle("Mes outils");
    }

    setCurrentTab(tab);
    setActiveTabIndexChanger(tab);
  }, [location.pathname]);

  function changeTab(tabId, url) {
    setCurrentTab(tabId);
    navigate(`/mon-compte/${url}`);
  }

  return (
    <div className='page-container'>
      <section className='container'>
        {subTabParam ? (
          <>
            <Breadcrumbs
              routes={[
                { name: "Accueil", to: "/" },
                { name: "Mes dossiers", to: `/mon-compte/mes-dossiers` },
                { name: "Mon dossier", to: `/mon-compte/mes-dossiers/${subTabParam}` },
              ]}
            />
            <Banner
              title='Mon dossier'
              subtitle={
                <Link className='link-with-arrow' to='/mon-compte/mes-dossiers'>
                  <ArrowSm /> Accéder à mes dossiers
                </Link>
              }
            />
          </>
        ) : (
          <>
            <Breadcrumbs
              routes={[
                { name: "Accueil", to: "/" },
                { name: pageTitle, to: `/mon-compte/${tabParam}` },
              ]}
            />
            <Banner title={pageTitle} subtitle='Rapports, factures, offres... Retrouvez toutes vos informations' />
          </>
        )}
      </section>
      <div className='sticky-container' ref={navbarRef}>
        <FiltersGroup
          changeActiveTabIndex={activeTabIndexChanger}
          data={[
            {
              name: "Mes outils",
              onClick: () => changeTab(0, "mes-outils"),
              params: [0],
            },
            {
              name: "Mes dossiers",
              onClick: () => changeTab(1, "mes-dossiers"),
              params: [1],
            },
            {
              name: "Mes formations",
              onClick: () => changeTab(2, "mes-formations"),
              params: [2],
            },
            {
              name: "Mon équipe",
              onClick: () => changeTab(3, "mon-equipe"),
              params: [3],
            },
            {
              name: "Mon profil",
              onClick: () => changeTab(4, "mon-profil"),
              params: [4],
            },
            {
              name: "Mes abonnements & factures",
              onClick: () => changeTab(5, "mes-abonnements-factures"),
              params: [5],
            },
          ]}
        />
      </div>
      {currentTab === 0 && <AccountTools />}
      {currentTab === 1 && (subTabParam ? <FolderDetail /> : <AccountFolders />)}
      {currentTab === 2 && <AccountFormations />}
      {currentTab === 3 && <AccountTeams />}
      {currentTab === 4 && <AccountProfile navbarRef={navbarRef} />}
      {currentTab === 5 && <AccountSubscriptionBills />}
    </div>
  );
}

export default MonCompte;
