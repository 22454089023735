import React, { useEffect, useContext, useState } from "react";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import { useForm } from "react-hook-form";
import Input from "../../../../molecules/formComponents/input/Input";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea.js";

function ActiviteExercee({ index }) {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  const {
    step,
    handleTabs,
    moveToNextStep,
    setStep,
    submitFormsMc,
    activites,
    getStepToGo,
    postValues,
    setGoToStep,
    estimationValues,
  } = useContext(ParcoursContext);
  const [displayedActivites, setDisplayedActivites] = useState(activites);
  const [activitesDropdownVisible, setActivitesDropdownVisible] = useState(false);
  const watchActivite = watch("activite");

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    setValue("activite", estimationValues?.infos?.activite?.description);
  }, [estimationValues]);

  useEffect(() => {
    if (!watchActivite) return setDisplayedActivites(activites);

    setDisplayedActivites(
      activites.filter(element => element.description.toLowerCase().includes(watchActivite.toLowerCase())),
    );
  }, [activites, watchActivite]);

  async function formSubmit(values) {
    let activiteId = activites.find(element => element.description === values.activite)?.id;
    if (!activiteId)
      return setError("activite", { message: "Veuillez choisir une activité dans la liste" }, { shouldFocus: true });

    let payload = {
      infos: { activite: { id: activiteId } },
      notes: {
        note_step_5: values.note_step_5,
      },
    };

    await postValues(payload);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  function setChosenActivite(activite) {
    setValue("activite", activite.description);

    setTimeout(() => {
      setActivitesDropdownVisible(false);
    }, 200);
  }

  function closeDropwdown() {
    setTimeout(() => {
      setActivitesDropdownVisible(false);
    }, 200);
  }

  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
        <fieldset className='borderless-fieldset pb-xl'>
          <h2>activité exercée (ou prévue) dans le local</h2>
          <Input
            className='mb-xxl'
            label='activité'
            icon='search'
            name='activite'
            disabled={estimationValues?.infos?.activite?.description ? true : false}
            onFocus={() => {
              setActivitesDropdownVisible(true);
              if (Number(step) === index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                submitFunction();
              }
            }}
            onBlur={closeDropwdown}
            useForm={{ register, setValue }}
            error={errors?.activite?.message}
            placeholder='Cliquez et déroulez, ou cherchez par mot-clé...'>
            <ul className={"activites-list murs-activites-list" + (activitesDropdownVisible ? "" : " h-none")}>
              {displayedActivites.map((activite, key) => {
                return (
                  <li key={key} onClick={() => setChosenActivite(activite)}>
                    {activite.description}
                  </li>
                );
              })}
              {displayedActivites.length === 0 && <li className='activites-no-result'>Aucun résultat</li>}
            </ul>
          </Input>
        </fieldset>
      </form>
    </>
  );
}

export default ActiviteExercee;
