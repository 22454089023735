import React, { useState, useEffect, useContext } from "react";
import "./AccountTools.css";
import Chart from "../../../../../components/atoms/icons/header/chart/Chart";
import Rapport from "../../../../../components/atoms/icons/header/rapport/Rapport";
import Emc from "../../../../../components/atoms/icons/header/emc/Emc";
import ComingSoon from "../../../../../components/atoms/icons/general/comingSoon/ComingSoon";
import Hr from "../../../../../components/molecules/hr/Hr";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { stringToDate } from "../../../../../utils/Utils";
import { fetchUserEstimationsApi } from "../../../../../api/EstimationApi";
import { useAuth } from "../../../../../context/AuthContext";
import AppContext from "../../../../../context/AppContext";
import { fetchAllFinancingPlanApi } from "../../../../../api/FinancingPlanApi";
import { fetchAllCreditSimulatorsApi } from "../../../../../api/CreditSimulatorApi";
import AccountToolsTab from "../accountsToolsTab/AccountToolsTab";

function AccountTools() {
  const tools = [
    { key: "estimations", name: "Mes estimations", type: "accessible", urlSegment: "mes-estimations" },
    {
      key: "financingPlans",
      name: "Mes plans de financement",
      type: "accessible",
      urlSegment: "mes-plans-de-financement",
    },
    {
      key: "creditSimulators",
      name: "Mes simulations de prêt",
      type: "accessible",
      urlSegment: "mes-tableaux-d-amortissement",
    },
    // { name: "Étude d'implantation", type: "comingSoon", urlSegment: "etude-d-implantation" },
    // { name: "Annonces", type: "comingSoon", urlSegment: "annonces" },
  ];
  const iconMap = {
    "Mes estimations": Emc,
    "Mes plans de financement": Rapport,
    "Mes simulations de prêt": Chart,
    // "Étude d'implantation": ComingSoon,
    // "Annonces": ComingSoon,
  };
  const [selectedTool, setSelectedTool] = useState(tools[0]);
  const [refreshItems, setRefreshItems] = useState(0);
  const [toolsLoaderVisible, setToolsLoaderVisible] = useState(true);
  const [items, setItems] = useState({
    estimations: [],
    financingPlans: [],
    creditSimulators: [],
  });
  const navigate = useNavigate();
  const { subTabParam } = useParams();
  const { getUuid } = useAuth();
  const { createNotification } = useContext(AppContext);

  useEffect(() => {
    fetchItems();
  }, [refreshItems]);

  useEffect(() => {
    setSelectedTool(tools.find(tool => tool.urlSegment === subTabParam) || tools[0]);
  }, [subTabParam]);

  async function fetchItems() {
    const estimations = await fetchEstimations();
    const financingPlans = await fetchFinancingPlans();
    const creditSimulators = await fetchCreditSimulators();

    setItems({ estimations, financingPlans, creditSimulators });
    setToolsLoaderVisible(false);
  }

  async function fetchEstimations() {
    let result = [];

    try {
      let res = (await fetchUserEstimationsApi(getUuid())).data;

      res = [...res["Fonds de commerce"], ...res["Murs commerciaux"], ...res["Titres de société"]];
      res.sort((a, b) => stringToDate(b.date_creation_estimation) - stringToDate(a.date_creation_estimation));

      result = res;
    } catch (error) {
      console.error(error);
      createNotification(
        <>Une erreur est survenue lors de la récupération de vos estimations. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      return result;
    }
  }

  async function fetchFinancingPlans() {
    let result = [];

    try {
      const res = (await fetchAllFinancingPlanApi(getUuid())).data;

      result = [...res.financing_plan_fdc, ...res.financing_plan_murs];
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de vos plans de financement. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      return result;
    }
  }

  async function fetchCreditSimulators() {
    let result = [];

    try {
      result = (await fetchAllCreditSimulatorsApi(getUuid())).data;
    } catch (error) {
      console.error(error);
      createNotification(
        <>Une erreur est survenue lors de la récupération de vos simulations de prêt. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      return result;
    }
  }

  function renderIcon(tool) {
    const IconComponent = iconMap[tool.name];
    return IconComponent ? <IconComponent width='20px' /> : null;
  }

  function navigateToTab(e, targetTool) {
    if (!tools.filter(tool => tool.urlSegment === targetTool.urlSegment && tool.type === "accessible").length)
      return e.preventDefault();

    setSelectedTool(targetTool);

    navigate(`/mon-compte/mes-outils/${targetTool.urlSegment}`);
  }

  return (
    <section className='container'>
      <div className='account-tools-page-container'>
        <div className='tools-sidebar'>
          <h2 className='tools-title'>Mes outils</h2>
          <Hr margin='20px 0' />
          <ul className='tools-tabs'>
            {tools.map((tool, index) => (
              <li key={index} className='tool-tab-container'>
                <Link
                  onClick={e => navigateToTab(e, tool)}
                  to={`/mon-compte/mes-outils/${tool.urlSegment}`}
                  className={`tool-tab ${selectedTool.name === tool.name ? "active" : ""} ${
                    tool.type === "comingSoon" ? "disabled" : ""
                  }`}>
                  <div>
                    {renderIcon(tool)}
                    {tool.name}
                  </div>
                </Link>
                {tool.type === "comingSoon" && <div className='soon-tag'>Bientôt</div>}
              </li>
            ))}
          </ul>
        </div>
        <div className='tool-content'>
          {selectedTool?.type ? (
            <AccountToolsTab
              isLoaderVisible={toolsLoaderVisible}
              type={selectedTool.key}
              items={items}
              refreshItems={() => setRefreshItems(prev => prev + 1)}
            />
          ) : (
            <Navigate to='/404' />
          )}
        </div>
      </div>
    </section>
  );
}

export default AccountTools;
