import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import Select from "../../../../molecules/formComponents/select/Select";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea";
import { max2250Validation } from "../../../../../utils/formValidation/FormValidation";
import { fetchDropdownValuesApi } from "../../../../../api/ParcoursApi";
import { formatObjectForPosting, getSelectValue, resetObjectValues } from "../../../../../utils/Utils";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto";
import AppContext from "../../../../../context/AppContext";

function NormesAccessibilite({ id, index }) {
  const {
    register,
    setValue,
    unregister,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const inputForm = { register, unregister, setValue };
  const {
    handleTabs,
    typesCommerce,
    moveToNextStep,
    getStepToGo,
    step,
    setStep,
    setGoToStep,
    fetchDropdownValues,
    submitFormsFdc,
    postValues,
    fillInputsFromEstimationValues,
    estimationValues,
    hasLocalDependance,
  } = useContext(ParcoursContext);
  const { showTooltip } = useContext(AppContext);
  const [dropdownsValues, setDropdownsValues] = useState({});
  const [localDependance, setLocalDependance] = useState(false);

  useEffect(() => {
    fillDropdownsValues();
  }, []);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
    setLocalDependance(hasLocalDependance(estimationValues));
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["normes_accessibilite", "materiel_agencements"], setValue, estimationValues);
    setValue("notes.note_step_11_1", estimationValues?.notes?.note_step_11_1);
    setValue("notes.note_step_11_2", estimationValues?.notes?.note_step_11_2);
  }, [estimationValues, dropdownsValues]);

  async function formSubmit(values) {
    if (!hasLocalDependance(estimationValues)) {
      let tmp = {
        materiel_agencements: values.materiel_agencements.etat_materiel,
        notes: values.notes.note_step_11_2,
      };

      values = resetObjectValues(values);
      values.materiel_agencements.etat_materiel = tmp.materiel_agencements;
      values.notes.note_step_11_2 = tmp.notes;
    }

    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    setDropdownsValues(
      await fetchDropdownValues(
        "accessibilite_pmr",
        "installations_electriques",
        "systeme_encaissement",
        "extraction",
        "etat_locaux",
        "etat_materiel",
      ),
    );
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <fieldset className={`borderless-fieldset ${localDependance ? "" : "d-none"}`}>
        <h2>normes & accessibilité</h2>
        <Select
          visible={localDependance}
          className={localDependance ? "" : "w-0"}
          label={
            <>
              accessibilité pmr (personne à mobilité réduite)
              <Tuto onClick={() => showTooltip("step_11_accessibilite_pmr.webp")} />
            </>
          }
          useForm={inputForm}
          error={errors?.normes_accessibilite?.accessibilite_pmr?.id?.message}
          name='normes_accessibilite.accessibilite_pmr.id'>
          {dropdownsValues?.accessibilite_pmr?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <div className='row-1000 mb-sm'>
          <Select
            shouldStickBottom={false}
            visible={localDependance}
            className={localDependance ? "" : "w-0"}
            label={
              <>
                conformité des installations électriques
                <Tuto onClick={() => showTooltip("step_11_installations_electriques.webp")} />
              </>
            }
            useForm={inputForm}
            error={errors?.normes_accessibilite?.installations_electriques?.id?.message}
            name='normes_accessibilite.installations_electriques.id'>
            {dropdownsValues?.installations_electriques?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
          <Select
            visible={localDependance}
            className={localDependance ? "" : "w-0"}
            label={
              <>
                conformité du système d’encaissement
                <Tuto onClick={() => showTooltip("step_11_systeme_encaissement.webp")} />
              </>
            }
            useForm={inputForm}
            error={errors?.normes_accessibilite?.systeme_encaissement?.id?.message}
            name='normes_accessibilite.systeme_encaissement.id'>
            {dropdownsValues?.systeme_encaissement?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
        </div>
        <Select
          className={typesCommerce.includes(3) && localDependance ? "" : "w-0"}
          visible={typesCommerce.includes(3) && localDependance}
          label='extraction'
          useForm={inputForm}
          error={errors?.normes_accessibilite?.extraction?.id && errors.normes_accessibilite.extraction.id.message}
          name='normes_accessibilite.extraction.id'>
          {dropdownsValues?.extraction?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <Textarea
          visible={localDependance}
          className={localDependance ? "mt-md" : "mt-md w-0"}
          useForm={inputForm}
          validation={max2250Validation}
          name='notes.note_step_11_1'
          label='notes et remarques'
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>matériel & agencements</h2>
        <div className='row-1000 mb-sm'>
          <Select
            shouldStickBottom={false}
            visible={localDependance}
            className={localDependance ? "" : "w-0"}
            label={
              <>
                etat des locaux
                <Tuto onClick={() => showTooltip("step_11_etat_locaux.webp")} />
              </>
            }
            useForm={inputForm}
            error={errors?.materiel_agencements?.etat_locaux?.id?.message}
            name='materiel_agencements.etat_locaux.id'>
            {dropdownsValues?.etat_locaux?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
          <Select
            label={
              <>
                etat du matériel
                <Tuto onClick={() => showTooltip("step_11_etat_materiel.webp")} />
              </>
            }
            useForm={inputForm}
            error={errors?.materiel_agencements?.etat_materiel?.id?.message}
            name='materiel_agencements.etat_materiel.id'>
            {dropdownsValues?.etat_materiel?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
        </div>
        <Textarea
          useForm={inputForm}
          validation={max2250Validation}
          name='notes.note_step_11_2'
          label='notes et remarques'
          className='mt-md'
          {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
        />
      </fieldset>
    </form>
  );
}

export default NormesAccessibilite;
